.banner-articles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    align-items: center;
    height: 100%;
  
    @include breakpoint('lg_down') {
        align-items: flex-start;
    }
    @include breakpoint('md_down') {
        grid-template-columns: auto;
    }

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height:  100vh;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, #CF7872 0%, #E2857E 26.2%);
        top: 105px;
        @media(max-height: 800px) {
            height: 905px;
         }
        @include breakpoint('lg_down') {
            height: 800px;
        }
    }

    &-wrapper {
        position: relative;
        background-color: var(--color-secondary) !important;
        .triangle {
            border-color: transparent transparent #E2857E transparent;
        }

        .banner-wrap {
           .header {
            @include breakpoint('md_down') {
                min-height: 105px;
             }
           }

           @include breakpoint('md_down') {
            height: 1202.49px;
           }
        }
    }
    
    &-image {
        position: relative;
        display: flex;
        height: 100%;
        justify-content: center;
        z-index: 8;
        align-items: center;
        @include breakpoint('lg_down') {
            align-items: baseline;
            margin-top: 400px;
            img {
                max-width: 418px;
            }
        }
        @include breakpoint('md_down') {
           margin-top: 183.74px;
           padding-left: 30.21px;
           img {
            max-width: 315px;
            max-height: 315px;
            margin: 0 auto 0 0 ;
        }
        }
    }

    &-text {
        z-index: 9;
        margin-top: 22.5px;
        @include breakpoint('lg_down') {
            margin-top:400px;
            max-width: 419.9px;
        }
        @include breakpoint('md_down') {
            margin-top:0;
            padding-left: 30.21px;

            .btn {
                position: absolute;
                bottom: 155.11px;
                margin: auto;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .title {
            color: var(--color-white);
            height: 82px;
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            line-height: 78px;
            margin-bottom: 87.5px;
            @include breakpoint('lg_down') {
                height: 82px;
                margin-bottom: 50.09px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 192.56px;
             }
             @include breakpoint('md_down') {
                font-size: 30px;
                line-height: 78px;
                top: 133.74px;
            }
        }

        .mini-title {
            height: 29.37px;
            text-align: left;
            color: var(--color-white);

            @include breakpoint('lg_down') {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 325.6px;
            }
            @include breakpoint('md_down') {
                position: relative;
                top: unset;
                left: unset;
                transform: unset;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.1em;
                height: 29.37px;
                margin-top: 31.72px;
            }
        }

        .header-text {
            color: #35476A;
            height: 176px;
            line-height: 58px;
            display: flex;
            align-items: center;
            @include breakpoint('lg_down') {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 348px;
                width: 100%;
                margin: 0 24px;

            }
            @include breakpoint('md_down') {
                position: relative;
                top: unset;
                left: unset;
                transform: unset;
                font-size: 30px;
                line-height: 35px;
                display: flex;
                align-items: center;
                margin-bottom: 1.53px;
                margin-left: 0;
                height: auto;
            }
        }
      
        .descriptionheader {
            height: 100px;
            margin-bottom: 36px;
       
            overflow: hidden;
            @include breakpoint('lg_down') {
                height: 222.44px;
                margin-bottom: 149.56px;
            }
            @include breakpoint('md_down') {
                max-width: 314.28px;
            }
            img {
                display: none;
            }
        }
    }
}

