// Container with all screens //
.container {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  max-width: 1300px;
  @include breakpoint('xlg_down') {
  padding: 0 51px;
  }
  @include breakpoint('slg_down') {
    padding: 0 40px;
  }
  @include breakpoint('xs_down') {
    padding: 0 30px;
  }
}

// Image for Responsive //
img {
  max-width: 100%;
  height: auto;
  display: block;
}

.pos-rel {
  position: relative;
}

// padding and margin 0 !important  //
.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

// Padding //
.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

// Margin //
.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mtn-60 {
  margin-top: -60px;
}

.mtn-40 {
  margin-top: -40px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

// width 100% //
.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

// Main title //
.main-title {
  h6 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.08em;
    margin: 0;
  }
}

.l-height {
  h4 {
    line-height: 30px;
  }

  h3 {
    line-height: 48px;
  }
}

// background color //
.bg-primary {
  background-color: var(--color-primary);
}
.bg-white {
  background-color: var(--color-white);
}
.bg-blue-light {
  background-color: var(--color-blue-light);
}
.bg-blue-lighter {
  background-color: var(--color-blue-lighter);
}
.bg-navy {
  background-color: var(--color-navy);
}
.bg-green {
  background-color: var(--color-green);
}
.bg-red {
  background-color: var(--color-secondary);
}

.bg-navy-light {
  background-color: #334464;
}

.bg-gradient-blue {
  background: linear-gradient(180deg, #62B1C2 0%, #6BBED0 100%);
}

// Colors //
.white {
  color: var(--color-white);
}
.primary {
  color: var(--color-primary);
}
.secondary {
  color: var(--color-secondary);
}
.green {
  color: var(--color-green-light);
}
.blue {
  color: var(--color-blue-light);
}
.navy-light {
  color: var(--color-navy-light);
}
.green-soft {
  color: var(--color-green-soft);
}

//Gradient//
.gr-navy {
  background: linear-gradient(180deg, #1E2A41 0%, #293856 25.49%);
} 
.gr-blue {
  background: linear-gradient(180deg, #7CBDCB 0%, #88CFDE 27.04%);
}
.gr-white-blue {
  background: linear-gradient(180deg, #F7F7F7 0%, #F2F2F2 7.92%, #7EC0CE 95.06%);
}
.gr-white-green {
  background: linear-gradient(180deg, #F7F7F7 0%, #F2F2F2 7.92%, #9CCBA4 95.06%);
}
.gr-green {
  background: linear-gradient(180deg, #79B589 0%, #8DCF9F 26.2%);
}



.gr-red {
  background: linear-gradient(180deg, #CF7872 0%, #E2857E 26.2%);
}
.gr-white {
  background: var(--color-white);
}

.bold {
  font-weight: 600;
}

// display flex with space between//
.items-between {
  @include display(flex, center, space-between);
}

// display flex with align items center //
.items-center {
  @include display(flex, center);
}

.items-end {
  @include display(flex, center, flex-end);
}

.center {
  @include display(flex, center, center);
}

// display flex with flex direction column //
.items-column {
  @include display(flex, initial, initial, column);
}

.next-section {
  @include position(absolute, initial, 0, 0, 0);
  height: 105px;
  z-index: 2;
  bottom: -1px;
}

//FullPage JS//
#fp-nav {
  ul {
    li {
      a  {
        span {
          width: 8px !important;
          height: 8px !important;
          background: rgba(0, 0, 0, 0.11) !important;
        }

        &.active {
          span {
            margin: -4px 0 0 -4px !important;
            background-color: var(--color-white) !important;
            width: 12px !important;
            height: 12px !important;
          }
        }  
      }

      &:hover {
        a {
          span {
            margin: -3px 0 0 -3px !important;
            background-color: var(--color-white) !important;
            width: 10px !important;
            height: 10px !important;
          }
        }
      }

      &:last-child {
        display: none;
      }

      .fp-tooltip {
        display: none !important;
      }
    }
  }
}

.arrow-navigatior {
  width: 49.89px;
  height: 49.89px;
  background: #31446A;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 191.41px;

  @include breakpoint('md_down') {
    bottom: 370.91px;
  }
  &-top {
    transform: translateX(-50%)  rotate(180deg);
    bottom: 30px;
    z-index: 9;
    cursor: pointer;
    @include breakpoint('lg_down') {
      transform: rotate(180deg) translateX(50%);
      bottom: 15px;
    }
    @include breakpoint('xs_down') {
      bottom: 0;
    }
  }
 
}



.triangle {
  display: flex;
  cursor: pointer;
  animation: bounce .8s ease-in-out infinite;
  border-width: 0 15px 30px 15px;
  border-color: transparent transparent var(--color-green-light) transparent; 
  border-style: solid;

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }

  &.navy {
    border-top-color: var(--color-navy);
  }
  &.blue {
    border-top-color: var(--color-blue-light);
    z-index: 3;
  }
}  

.z-index-i {
 z-index: initial !important;
}

// FadeIn Animation //
@keyframes fadeInUp {
  from { 
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.header-text {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;

  @include breakpoint('slg_down') {
    font-size: 30px;
    line-height: 38px;     
  }
  @include breakpoint('xs_down') {
    font-size: 26px;
    line-height: 33px;    
  }
}

.description {
  font-style: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #35476A;
  @include breakpoint('slg_down') {
    font-size: 18px;
    line-height: 23px;
  }
  @include breakpoint('xs_down') {
    font-size: 16px;
    line-height: 20px; 
  }
}

.mini-title {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1em;
  height: 32.04px;
  color: #4B5E8A;
}

.flex {
  display: flex;
}

.disable-scroll {
  @include breakpoint('lg_down') {
    overflow: hidden;    
  }
}