.get-started-wrap {
    height: 100%;

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, #283652 0%, #35476A 25.49%);
        top: 100px;
    }
}

.get-started {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    align-items: center;
    height: 100%;
    
    &-image {
        position: relative;
        @include display(flex, flex-end);
        height: 100%;
        
        img {
            z-index: 3;
            max-width: 600px;
        }
    }

    &-content {
        @include display(flex, center, flex-end, column);
        height: 100%;
        z-index: 4;

        &-description {     
            @include display(flex, initial, center, column);
            height: calc(100% - 480px);

            h1 {
                color: var(--color-white);
                font-weight: 600;

                span{
                    color: var(--color-secondary)
                }
            }       
            h5 {
                color: var(--color-blue-light);
                font-weight: 400;
                max-width: 490px;
                line-height: 28px;
                font-family: 'Proxima Nova';
            }
        }
    }
}

.next-section--started-h {
    height: 72px;
}