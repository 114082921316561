.banner-our-team {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    align-items: center;
    height: 100%;

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, #7CBDCB 0%, #88CFDE 27.04%);
        top: 100px;
    }
    
    &-image {
        position: relative;
        @include display(flex, flex-end);
        height: 100%;
        
        img {
            z-index: 3;
            max-width: 700px;
        }
    }

    &-content {
        &-description {  
            z-index: 2;   
            h1 {
                color: var(--color-primary);
                font-weight: 600;

                span{
                    color: var(--color-white)
                }
            }       
            
            h4 {
                color: var(--color-primary);
                font-weight: 400;
                max-width: 540px;
                line-height: 32px;
                font-family: 'Proxima Nova';
            }
        }
    }
}

.next-section--started-h{
    height: 72px;
}