.banner-about-us {
    display: flex;
    align-items: center;
    height: 100%;

    @include breakpoint('lg_down') {
        flex-direction: column-reverse;
     }
 

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100vh;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, #151D2E 0%, #1E2843 25.49%);
        top: 105px;

        @media(max-height: 800px) {
            height: 905px;
        }
    }

    &-img {
        position: relative;
        z-index: 99;
        height: 100%;
        display: flex;
        @include breakpoint('xlg_down') {
            flex: 1;
         }
         @include breakpoint('sm_down') {
            max-width: 329.81px;
        }

        img {
            margin-top: auto;
        }
    }


    &-text {
        position: relative;
        z-index: 99;
        margin-left: auto;
        @include breakpoint('xlg_down') {
           margin-left: 8px;
           flex: 1;
        }
        @include breakpoint('lg_down') {
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin-top: 124px;
         }
         @include breakpoint('sm_down') {
           justify-content: flex-start;
           margin-top: 58.89px;
        }
        h1 {
            color: var(--color-green);
            margin: 0;
            position: relative;
            margin-bottom: 28px;
            max-width: 743.8px;

            @include breakpoint('md_down') {
                line-height: 63px;
                font-size: 50px;
            }
            @include breakpoint('sm_down') {
                font-size: 40px;
                line-height: 50px;
            }

        }

        .description {
            max-width: 525.95px;
            color: var(--color-green);
            @include breakpoint('sm_down') {
                font-size: 18px;
                line-height: 23px;
            }
            @include breakpoint('xs_down') {
                max-width: 315px;
            }
        }
    }

    &-wrap {
        height: 100vh;
        min-height: 905px;

    }

    &-wrapper {
        position: relative;
        overflow: hidden;
        background: #2A3756;

        .next-section{
            background: #2A3756;
        }
        .btn {  
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            background-color: var(--color-green);
            color: var(--color-white);
            width: 168px;
            height: 56px;
            @include breakpoint('lg_down') {
              margin: auto;
            }
          }
        
        .bg-stars-wrapper{
            position: absolute;
            top: 105px;
            left: 0;
            right: 0;
            height: 100%;
            z-index: 99;
            min-width: 1562.75px;
            @include breakpoint('lg_down') {
                min-width: 1000px;
              }
            @include breakpoint('sm_down') {
               display: none;
             }
            img {
                margin: 0 auto;
                
            }
        }

        .bg-bottom-bar {
            z-index: 9;
            width: 100%;
            position: absolute;
            bottom: 105px;
            left: 0;
            right: 0;

            @include breakpoint('lg_down') {
                min-width: 1300px;
                height: 199.32px;
            }
            @include breakpoint('sm_down') {
                min-width: unset;
                bottom: 65px;
            }


            .bar-bottom-about-us {
                width: 100%;
                @include breakpoint('sm_down') {
                    display: none;
                }
            }
            .bar-bottom-mobile-about-us {
                display: none;
                @include breakpoint('sm_down') {
                    width: 100%;
                    display: block;
                    height: 199.32px;
                    object-fit: cover;
                }
            }
        }

        .banner-wrap {
            @include breakpoint('lg_down') {
              height: 1203px;
            }
            @include breakpoint('md_down') {
                height: 1268px;
            }
            @include breakpoint('sm_down') {
                height: 800px;
            }
        }
        
        .main-menu-indicator {
          .line {
                background: var(--color-green);

                &::before, &::after {
                    background: var(--color-green);
                }
          }
        }
          
        .main-menu-indicator.active {
            .line {
                  background: transparent;
            }
          }
    }


}