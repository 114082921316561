.testimonials {
    margin-bottom: 77.73px;
    padding: 0 30px;

    @include breakpoint('sm_down') {
        padding: 0 35.43px;
    }
    &-wrapper {
        max-height: unset !important;
        height: 100% !important;
        position: relative;
        overflow: hidden;

        .container {
            margin-top: -1px;
            @include breakpoint('xlg_down') {
                padding: 0;
            }
        }

        .gr-navy {
            background: linear-gradient(180deg, #1E2A41 0%, #293856 25.49%);
        }

        .fp-tableCell {
            height: 100% !important;
        }
    }

    &-section {
        .header-text {
            min-height: 69.38px;
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 50px;
            line-height: 63px;
            margin: 122.87px auto 14.39px auto;
            text-align: center;
            @include breakpoint('sm_down') {
                font-size: 40px;
                line-height: 50px;
                margin: 79.86px auto 14.39px auto;
            }
        }

        .description {
            min-height: 45.21px;
            font-weight: 600;
            margin: 0 auto;
            color: var(--color-white);
            text-align: center;
            margin-bottom: 68.03px;
            @include breakpoint('sm_down') {
                font-weight: 600;
                font-size: 18px;
                line-height: 23px;
                margin-bottom: 14.38px;
                white-space: nowrap;
            }
        }
    }

    &-cards {
        grid-template-columns: auto auto;
        display: grid;
        height: 100%;
        row-gap: 44.97px;
        place-items: center;
        column-gap: 48.12px;

        @include breakpoint('lg_down') {
            grid-template-columns: auto;
          row-gap: 63.82px;
        }
    }

    &-card {
        height: 537px;
        z-index: 9;
        position: relative;
        @include breakpoint('sm_down') {
            height: 100%;   
        }
        &-top {
            display: flex;
            width: 611.24px;
            height: 387.11px;
            background: #8DCF9F;
            border-radius: 20px 20px 0px 0px;

            @include breakpoint('xlg_down') {
                width: auto;
            }

            @include breakpoint('sm_down') {
                height: 636.55px;
               flex-direction: column-reverse;
            }

            .description {
                width: 247.65px;
                min-height: 298.02px;
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: 0.02em;
                color: #31446A;
                overflow: hidden;
                padding-top: 50.01px;
                overflow: visible;

                @include breakpoint('xlg_down') {
                    padding-right: 16px;
                    width: auto;
                    max-width: 247.65px;
                }

                @include breakpoint('lg_down') {
                   padding: 50.01px 49.12px 30.08px 0;
                   text-align: left;
                }
                @include breakpoint('sm_down') {
                    padding: 41.58px 19.19px 0 23.24px;
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    max-width: 400px;
                    min-height: unset;
                    white-space: unset;
                    margin: 0;
                    position: absolute;
                    top: 0;
                 }
            }

            .star-bg {
                display: block;

                @include breakpoint('sm_down') {
                    display: none;
                }
            }

            .star-bg-tablet {
                display: none;

                @include breakpoint('sm_down') {
                    display: block;
                    margin: 0 auto;
                }
            }
        }

        &-bottom {
            width: 611.24px;
            max-height: 127px;
            height: 100%;
            background: #FFFFFF;
            border-radius: 0px 0px 20px 20px;
            padding-top: 23.19px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include breakpoint('xlg_down') {
                width: auto;
            }

            @include breakpoint('sm_down') {
                min-height: 214px;
                padding-top: 31.73px;
                max-height: 246.68px;
            }
            .name {
                height: 29.21px;
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 24px;
                text-align: center;
                color: #31446A;
                margin-bottom: 12.76px;

                @include breakpoint('lg_down') {
                    margin-bottom: 10.02px;
                }

                @include breakpoint('sm_down') {
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 21.16px;
                }
            }

            .job {
                @include breakpoint('md_down') {
                    margin-top: auto;
                }
            }

            .wrapper-job {
                @include breakpoint('md_down') {
                    margin: auto;
                }
                @include breakpoint('sm_down') {
                    padding: 0 20px;
                    margin: 0 auto;
                    max-width: 265px;
                }
            }

            .job,
            .former-job {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                letter-spacing: 0.02em;
                color: #31446A;

                @include breakpoint('sm_down') {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
    }

    .image-wrapper {
        position: relative;

        .avatar-image {
            position: absolute;
            left: 45%;
            transform: translateX(-50%);
            bottom: 0;

            @include breakpoint('sm_down') {
                width: 255.23px;
                height: 350.97px;
                left: 50%;
                bottom: -7px;
            }
        }

        @include breakpoint('lg_down') {
            margin: auto;
        }

        @include breakpoint('md_down') {
            margin: auto 0 5px 0;
        }
    }

    .testimonial-wrapper-card {
        position: relative;

        .testimonial-shadow {
            display: block;
            width: 611.24px;
            height: 537.11px;
            background: #1C2942;
            border: 1px solid #1C2942;
            border-radius: 20px;
            position: absolute;
            top: 20px;
            left: 20px;

            @include breakpoint('xlg_down') {
                width: 100%;
            }
            @include breakpoint('sm_down') {
                height: 100%;
            }
        }

    }
}