.our-road-map {
    &-wrapper {
        position: relative;
        background: linear-gradient(180deg, #0D1A32 14.72%, #293856 54.42%);
        margin-top: -2px;
        overflow: hidden;

        .road-lines {
            position: absolute;
            top: -170px;
            right: 33%;
            height: 830px;
            width: 726px;
        }

        .road-image-wrapper {
            position: relative;

            .road-line-dots {
                left: 675px;
                position: absolute;
                top: 190px;

                @include breakpoint('slg_down') {
                    display: none;
                }
            }

            .road-line-dots-tablet {
                display: none;

                @include breakpoint('slg_down') {
                    left: 645.08px;
                    position: absolute;
                    top: 190px;
                    display: block;
                }

                @include breakpoint('xs_down') {
                    display: none;
                }
            }

            .road-line-dots-mobile {
                display: none;

                @include breakpoint('xs_down') {
                    left: 450px;
                    position: absolute;
                    top: 765px;
                    display: block;
                }
            }

            .left-text {
                display: flex;
                flex-direction: column;
                gap: 67.17px;
                position: absolute;
                left: 502px;
                top: 199px;

                @include breakpoint('xlg_down') {
                    left: 528px;
                }

                @include breakpoint('slg_down') {
                    left: 538.08px;
                }

                @include breakpoint('xs_down') {
                    display: none;

                }

                span {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: right;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: var(--color-white);
                    height: 25.11px;
                    text-align: right;

                    @include breakpoint('slg_down') {
                        font-size: 8.86619px;
                        line-height: 11px;
                    }

                    @include breakpoint('xs_down') {
                        font-size: 9.84616px;
                        line-height: 12px;
                        text-align: right;
                        letter-spacing: 0.1em;
                    }
                }

                span:nth-child(1) {
                    margin-top: -2px;

                    @include breakpoint('slg_down') {
                        margin-top: -3px;

                    }
                }

                span:nth-child(2) {
                    @include breakpoint('slg_down') {
                        margin-top: -37px;

                    }
                }

                span:nth-child(3) {
                    margin-top: -4px;

                    @include breakpoint('slg_down') {
                        margin-top: -35px;

                    }

                    @include breakpoint('xs_down') {
                        margin-top: -20px;

                    }
                }

                span:nth-child(4) {
                    margin-top: 100px;

                    @include breakpoint('slg_down') {
                        margin-top: 30px;

                    }

                    @include breakpoint('xs_down') {
                        margin-top: 60px;

                    }
                }

                span:nth-child(5) {
                    margin-top: 38px;

                    @include breakpoint('slg_down') {
                        margin-top: -10px;

                    }
                }

                span:nth-child(6) {
                    margin-top: -30px;

                    @include breakpoint('slg_down') {
                        margin-top: -48px;

                    }

                    @include breakpoint('xs_down') {
                        margin-top: -50px;

                    }
                }
            }

            .right-text {
                display: flex;
                flex-direction: column;
                gap: 0;
                position: absolute;
                left: 791px;
                top: 188px;

                @include breakpoint('xlg_down') {
                    left: 759px;
                }

                @include breakpoint('slg_down') {
                    left: 720px;
                }

                @include breakpoint('xs_down') {
                    left: 190px;
                    display: none;
                }

                span {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                    color: #88CFDE;
                    height: 91.43px;
                    width: 297.72px;

                    @include breakpoint('slg_down') {
                        font-size: 12px;
                        line-height: 14px;
                        width: 224.12px;
                        height: 58px;
                    }

                    @include breakpoint('xs_down') {
                        width: 165.82px;
                        height: auto;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.02em;
                    }

                }

                span:nth-child(1) {
                    @include breakpoint('xs_down') {
                        margin-top: 10px;

                    }
                }

                span:nth-child(2) {
                    @include breakpoint('xs_down') {
                        margin-top: 10px;

                    }
                }

                span:nth-child(3) {
                    @include breakpoint('xs_down') {
                        margin-top: 14px;

                    }
                }

                span:nth-child(4) {
                    @include breakpoint('xs_down') {
                        margin-top: 10px;

                    }
                }

                span:nth-child(5) {
                    margin-top: 15px;

                    @include breakpoint('slg_down') {
                        margin-top: 9px;

                    }
                }

                span:nth-child(6) {
                    margin-top: 34px;

                    @include breakpoint('slg_down') {
                        margin-top: 28px;

                    }

                    @include breakpoint('xs_down') {
                        margin-top: 15px;

                    }
                }

                span:nth-child(7) {
                    margin-top: -19px;

                    @include breakpoint('slg_down') {
                        margin-top: -16px;

                    }

                    @include breakpoint('xs_down') {
                        margin-top: 15px;

                    }
                }

            }
        }

        .shaped-bg {
            background: #09152C;
            -webkit-clip-path: polygon(40% 9%, 80% 0, 100% 4%, 100% 70%, 100% 100%, 0 98%, 0 70%, 0 12%);
            clip-path: polygon(40% 9%, 80% 0, 100% 4%, 100% 70%, 100% 100%, 0 98%, 0 70%, 0 12%);
            height: 277.06px;
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;
            top: 228.4px;

            @include breakpoint('lg_down') {
                top: 144px;
            }

            @include breakpoint('xs_down') {
                top: 374.4px;
            }
        }

        .bg-grayed {
            background: linear-gradient(180deg, #09152C 0%, #293856 48.06%);
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;
            bottom: 0;
            height: 302.08px;

            @include breakpoint('lg_down') {
                top: 245px;
            }

            @include breakpoint('slg_down') {
                top: 276px;
                height: 147px;
            }

            @include breakpoint('xs_down') {
                top: 504px;
            }
        }
    }

    &-container {
        margin: auto;
        max-width: 1300px;
        width: 100%;
        position: relative;
        min-height: 712px;
        z-index: 9;

        @include breakpoint('slg_down') {
            min-height: 458px;
        }

        @include breakpoint('xs_down') {
            min-height: 1225px;
        }

        .clouds-img {
            top: 15.61px;
            left: -24px;

            @include breakpoint('lg_down') {
                padding-top: 28px;
            }

            @include breakpoint('slg_down') {
                width: 268.96px;
                height: 75.99px;
            }

            @include breakpoint('xs_down') {
                width: 466.72px;
                height: 121.99px;
                padding-top: 21.57px;
            }
        }

        .car-text {
            position: relative;
            margin-left: 49.53px;
            padding-top: 134px;

            @include breakpoint('lg_down') {
                padding-top: 19px;
            }

            @include breakpoint('slg_down') {
                margin-left: 30px;
                padding-top: 65px;
            }

            @include breakpoint('xs_down') {
                padding-top: 245px;
            }

            .car-in-road-img {
                @include breakpoint('sm_down') {
                    max-width: 395px;
                }
                @include breakpoint('xs_down') {
                    margin-left: 6px;
                }
            }

            .road-text {
                position: absolute;
                bottom: -25px;
                left: 28px;

                @include breakpoint('slg_down') {
                    bottom: 70px
                }
                @include breakpoint('sm_down') {
                    bottom: -25px;
                }
                @include breakpoint('xs_down') {
                    bottom: unset;
                    top: 76.34px;
                    left: 44.57px;
                }
            }

            h2 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 50px;
                color: var(--color-blue-light);
                margin-bottom: 17.28px;

                @include breakpoint('slg_down') {
                    font-size: 26px;
                    line-height: 33px;
                    margin-bottom: 13.2px;
                }

                @include breakpoint('xs_down') {
                    font-size: 30px;
                    line-height: 38px;
                    margin-bottom: 19.55px;
                }

            }

            p {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.02em;
                color: var(--color-white);
                width: 364.04px;

                @include breakpoint('slg_down') {
                    font-size: 12px;
                    line-height: 15px;
                    width: 207.46px;
                }

                @include breakpoint('xs_down') {
                    font-size: 14px;
                    line-height: 18px;
                    width: 280.04px;
                }
            }
        }

    }



}