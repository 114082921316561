.what-we-do {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  &-wrapper {
    .next-section {
      background-color:  #354466;
    }
  }

  &-image {
    position: relative;
    @include display(flex, flex-end);
    height: 100%;


  }

  &-content {
    @include breakpoint('lg_down') {
      max-width: 675.04px;
      margin: auto;
    }

    .header-text {
      color: #82C394;
      min-height: 63px;
      text-align: center;
      margin-top: 91.73px;
      margin-bottom: 16px;

      @include breakpoint('xs_down') {
        font-weight: 600;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 41px;
        padding: 0 25px;
       }
    }

    .description {
      color: #8DCF9F;
      text-align: center;
      min-height: 40px;
      max-width: 986px;
      text-align: center;
      margin: 0 auto 160px auto;

      @include breakpoint('lg_down') {
        height: auto;
        margin-bottom: 39.94px;
        max-width: 647.1px;
      }

      @include breakpoint('xs_down') {
        padding: 0 47px;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
       }
    }
  }

  &-section {
    min-height: 1030px;
    @include breakpoint('xlg_down') {
      padding: 0;
    }
    @include breakpoint('lg_down') {
      height: 1358px;
    }

    @include breakpoint('sm_down') {
      height: 1645px;
    }
    @include breakpoint('sm_down') {
      height: 1700px;
    }

    .mini-title {
      margin-top: 40.55px;
    }
  }

  .boxes-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 399.27px) 402.76px;
    grid-auto-rows: minmax(100px, 285.1px);
    gap: 46px;
    margin: auto;
    

    @media   (max-width: 1440px) {
      grid-template-columns: 370px 370px 370px;
      gap: 24px;
    }

    @include breakpoint('lg_down') {
      grid-template-columns: auto;
      grid-auto-rows: minmax(100px, 207.63px);
      gap: 43.43px;
      max-width: 700px;
    }
    @include breakpoint('sm_down') {
      padding: 0 24px;
     }
     @include breakpoint('xs_down') {
      grid-auto-rows: minmax(100px, 300px);
      gap: 23px;
     }

    .box-container {
      position: relative;

      @include breakpoint('lg_down') {
        max-height: 179px;
       }
       @include breakpoint('xs_down') {
        max-height: 273px;
        max-width: 300px;
       }
      .box-shadow-behind {
        display: block;
        width: 399.27px;
        height: 318px;
        background: #1C2942;
        border: 1px solid #1C2942;
        border-radius: 20px;
        position: absolute;
        top: 17px;
        left: 19px;
        @media   (max-width: 1440px) {
          width: 360px;
          top: 12px;
        }
        @include breakpoint('lg_down') {
         width: 100%;
         height:207.63px;
         top: 20px;
         left: 21px;
        }
        @include breakpoint('xs_down') {
         height: 270px;
         width: 300px;
         }
      }
    }

    .box {
      border-radius: 20px;
      padding: 30.03px 20px 0 48.35px;
      z-index: 9;
      position: relative;
      max-height: 285.03px;
      height: 100%;

      @include breakpoint('lg_down') {
        padding: 26.23px 23.24px 0 33.57px;
        max-height: 207.63px; 
      }

      @include breakpoint('md_down') {
        padding: 25.03px 28.55px 0 28.75px;
      }
      @include breakpoint('xs_down') {
        padding: 25.03px 28.55px 0 28.75px;
        max-height: 300px;
        }
      &-blue {
        background-color: var(--color-blue-light);
        border: 1px solid #88CBDE;
      }

      &-red {
        background-color: #D7807A;
        border: 1px solid #E2857E;
        padding-left: 39.56px;

        @include breakpoint('lg_down') {
          padding-left: 27.47px;
        }

        @include breakpoint('md_down') {
          padding: 31.39px 26.55px 0 30.75px;
        }
      }

      &-green {
        background: var(--color-green);
        border: 1px solid var(--color-green);
        padding-left: 41.11px;

        @include breakpoint('lg_down') {
          padding-left: 28.54px;
        }

        @include breakpoint('md_down') {
          padding: 25.39px 25.55px 0 30.75px;
        }
      }

      &-title {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 45px;
        color: var(--color-white);
        margin-bottom: 32.16px;
        text-align: left;

        @include breakpoint('lg_down') {
          text-align: center;
          margin-bottom: 23.58px;
        }
        @include breakpoint('xs_down') {
          font-weight: 600;
          font-size: 30px;
          line-height: 38px;
         }
      }

      &-description {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        color: #31446A;
        max-width: 317.45px;
        text-align: left;
        @include breakpoint('lg_down') {
          text-align: center;
          max-width: 592px;
        }
        @include breakpoint('xs_down') {
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
         }
      }
    }
  }

  .btn-wrapper {
    display: none;
    margin-top: 61.48px;
    justify-content: center;
    max-width: 1324px;
    z-index: 9;

    @include breakpoint('lg_down') {
      margin-top: 84.04px;
      display: flex;
    }

    @include breakpoint('md_down') {
      margin-top: 83.96px;
    }

    .btn {
      width: 241px;
    }
  }

  &-wrapper {
    position: relative;
    margin-top: -1px;
    overflow: hidden;
  }
}


.banner-what-we-do-wrapper {
  position: relative;

  .next-section {
    background-color: #2A3756;
  }

  @include breakpoint('lg_down') {
    .main-menu-indicator {
      .line {
        background-color: #8DCF9F;

        &::after,
        &::before {
          background-color: #8DCF9F;
        }
      }

      &.active {
        .line {
          background-color: white;

          &::after,
          &::before {
            background-color: #3F5475;
          }
        }
      }

    }
  }

  .header-buttons {
    .btn--secondary {
      background: #8DCF9F;
      color: #31446A;
    }
  }

  @include breakpoint('lg_down') {
    height: 1105px;
  }

  @include breakpoint('md_down') {
    height: 809.04px;
  }

  .banner-wrap {
    min-height: 1010px;
  }
}