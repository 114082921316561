.banner {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: inherit;
    justify-content: space-between;
    @include breakpoint('xlg_down') {
      max-width: unset;
    }

    @include breakpoint('xs_down') {
      flex-direction: column;
      text-align: left;
      align-items: baseline;
      height: 100%;
      padding: 0 30px;
    }

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height:  100vh;
      left: 0;
      right: 0;
      background: var(--color-blue-light);
      top: 105px;
      max-height: 810px;
    }
    .leaf {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;

      @include breakpoint('slg_down') {
        width: 333.67px;
        height: 320.17px;
      }
      @include breakpoint('xs_down') {
        width: 352.1px;
        height: 335.19px
      }
    }
    &-header {
    
        &-text {
          position: relative;
          z-index: 11;
          max-width: 743.8px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          min-height: inherit;
          @include breakpoint('xlg_down') {
            max-width: unset;
          }
            h1 {
                color: rgba(53, 71, 106, 1);
                margin: 0;
                position: relative;
                margin-bottom: 36px;
                width: 532.41px;
                margin-top: 182.22px;
                @include breakpoint('slg_down') {
                  max-width: 443.53px;
                  margin-top: 162px;
                  margin-bottom: 18.99px;
                }
                @include breakpoint('xs_down') {
                  max-width: 324.36px;
                  margin-top: 46.64px;
                  margin-bottom: 9.2px;
                }
        

                span {
                  color: var(--color-white);
                }
                        
                
                @keyframes animate {
                  0% {
                    width: 0;
                  }
                
                  100% {
                    width: 100%;
                  }
  
                  0% {
                    width: 0;
                  }
                }
  
                @keyframes animate1 {
                  0% {
                    opacity: 0;
                  }
                
                  100% {
                    opacity: 1;
                  }
                }
                
            }

          .description {
            width: 526.88px;
            margin-bottom: 34.09px;
            @include breakpoint('slg_down') {
              max-width: 332.74px;
              margin-bottom: 42.39px;
            }
            @include breakpoint('xs_down') {
              max-width: 327.33px;
              margin-bottom: 27.78px;
            }
         
          }

          .btn {  
            font-size: 18px;
            line-height: 26px;
            height: 56px;
            padding: 0 34.93px;
            font-weight: 600;
            min-width: 168px;
            border-width: 1.5px;
        
            @include breakpoint('slg_down') {
              border: 1.5px solid #464967;
              border-radius: 40px;
              width: 161px;
              height: 56px;
              font-weight: 600;
              font-size: 18px;
              line-height: 26px;
            }
            @include breakpoint('xs_down') {
              font-weight: 600;
              font-size: 18px;
              line-height: 26px;
              border: 0.908578px solid #464967;
              min-width: 168px;
              height: 40px;
            }
          }
        }
   
        &-image {
            z-index: 10;
            position: relative;
            height: 100%;
            display: flex;
            align-items: end;
            min-height: inherit;
            @include breakpoint('xlg_down') {
             position: absolute;
              right: 51px;
            }
            @include breakpoint('slg_down') {
               right: 40px;
             }
            @include breakpoint('xs_down') {
              right: 0;
              left: 0;
              bottom: 0;
            }
       
          .monkeys-img {
            max-width: unset;
            @include breakpoint('xlg_down') {
              max-width: 100%;
              min-width: 442.7px;
              min-height: 312.77px;
            }
            @include breakpoint('lg_down') {
              max-width: 570px;
             }
             @include breakpoint('md_down') {
              max-width: 450px;
             }
            @include breakpoint('slg_down') {
              max-width: 442.7px;
              max-height: 312.77px;
            }
            @include breakpoint('xs_down') {
              max-width: 352.42px;
              max-height: 248.99px;
              min-width: unset;
              min-height: unset;
              margin: 0 auto;
            }
          }
    
        }
    }

  .triangle {
    display: flex;
    border-top: 20px solid var(--color-green-light);
    border-bottom: 15px solid transparent;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    cursor: pointer;
    animation: bounce .8s ease-in-out infinite;

    @keyframes bounce {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-5px);
      }
    }
  }  

  &-wrap {
    height: 810px;

    @include breakpoint('slg_down') {
      height: 100%;
      min-height: 660px;
    }
    @include breakpoint('xs_down') {
      padding: 0;
      min-height: 570px;
    }
  }

  &-wrapper {
    position: relative;
    overflow: hidden;
    min-height: inherit;
    .next-section {
      @include breakpoint('slg_down') {
        height: 90px;
      }
      @include breakpoint('xs_down') {
        height: 80px;
      }
     }
  }

  .partners {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 25px;
    @include breakpoint('xs_down') {
     display: none;
   }
    &-desc {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: var(--color-white);
      margin-bottom: 17.02px;
      
      @include breakpoint('slg_down') {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 13.43px;
     }
    }

    .partner-box {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
  
      img {
        margin-bottom: 8.36px;
        width: 70px;   
        @include breakpoint('sm_down') {
          width: 49.81px;
          height: 49.81px;
       }
        @include breakpoint('xs_down') {
          width: 50.53px;
          height: 57.45px;
       }
      }
    }
    &-wrappers {
       display: flex;
       gap: 18.93px;
       @include breakpoint('sm_down') {
        gap: 12.74px;
       }
    }
    &-title {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: var(--color-white);
      @include breakpoint('sm_down') {
        font-weight: 600;
        font-size: 9px;
        line-height: 11px;
     }
    }
  }
}