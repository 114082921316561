.testimonial {
    height: 100vh;

    &-container {
        margin: auto;
        max-width: 1300px;
        width: 100%;
        position: relative;
        padding-top: 48.6px;
        margin-top: -2px;
        padding: 48.6px 0 105.12px 0;

        @include breakpoint('slg_down') {
            padding: 32.63px 0 70.57px 0;
        }
        @include breakpoint('xs_down') {
            min-height: 632.91px;
        }

        .testimonial-box-img {
            @include breakpoint('slg_down') {
                display: none;
            }
        }

        .testimonial-box-img-tablet {
            display: none;

            @include breakpoint('slg_down') {
                display: block;
                margin: auto;
            }

            @include breakpoint('xs_down') {
                display: none;
            }
        }

        .testimonial-box-img-mobile {
            display: none;

            @include breakpoint('xs_down') {
                display: block;
                margin: auto;
            }
        }


        .cloud1 {
            position: absolute;
            left: -62.04px;
            top: 79px;

            @include breakpoint('slg_down') {
                left: -18px;
                top: 42.01px;
                width: 94.67px;
                height: 37.85px;
            }

            @include breakpoint('xs_down') {
                top: 394.57px;
            }

        }

        .cloud2 {
            position: absolute;
            right: 0;
            top: 325.1px;
            z-index: 9;

            @include breakpoint('slg_down') {
                top: 188.26px;
                right: -74px;
                width: 182.33px;
                height: 72.9px;
            }

            @include breakpoint('xs_down') {
                top: 634.33px;
                right: 9px;
            }
        }

        .wrapper-box-testimonial {
            margin: 0 auto;
            display: flex;
            gap: 25.48px;
            justify-content: center;

            @include breakpoint('xs_down') {
                flex-direction: column-reverse;
                justify-content: flex-start;
                gap: 0;
            }
        }

        .box-text {
            position: relative;

            @include breakpoint('xs_down') {
                max-width: 350px;
                margin: 0 auto;
            }

            .text-inside {
                position: absolute;
                top: 37.17px;
                width: 629.75px;
                left: 118.17px;
                font-size: 24px;
                line-height: 30px;
                height: 270px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include breakpoint('slg_down') {
                    top: 25.92px;
                    left: 74.98px;
                    height: 180px;
                    max-width: 422px;
                    width: unset;
                }

                @include breakpoint('xs_down') {
                    top: 40.47px;
                    left: 26.09px;
                    width: 301.43px;
                    height: 200px;
                }
            }

            h2,
            li {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 25px;
                line-height: 32px;
                display: flex;
                align-items: center;
                letter-spacing: 0.02em;
                color: var(--color-secondary);

                @include breakpoint('slg_down') {
                    font-size: 16px;
                    line-height: 20px;
                }

                @include breakpoint('xs_down') {
                    font-size: 16px;
                    line-height: 20px;
                    
                }
            }

            ul {
                list-style: none;
            }

            ul li::before {
                content: "\2022";
                color: var(--color-secondary);
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: 0;
            }
        }

        .img-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 60px;

            @include breakpoint('xs_down') {
                margin-top: 0;
            }

            .joe-img {
                margin-bottom: 15.54px;


                @include breakpoint('xs_down') {
                    margin-top: 21.34px;
                    width: 117.76px;
                    height: 117.76px;
                }
            }

            h2 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 24px;
                text-align: center;
                color: var(--color-white);
                margin-bottom: 7.08px;

                @include breakpoint('slg_down') {
                    font-size: 14px;
                    line-height: 16px;
                }
            }

            p {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                letter-spacing: 0.02em;
                color: var(--color-lighter-blue);
                width: 142.35px;

                @include breakpoint('slg_down') {
                    font-size: 10px;
                    line-height: 13px;
                    width: 95.57px;
                }

                @include breakpoint('xs_down') {
                    font-size: 12px;
                    line-height: 15px;
                    width: 207.5px;
                }
            }
        }

    }

    &-wrapper {
        background: linear-gradient(0deg, #0D1A32 14.72%, #293856 96.68%);
        position: relative;
        overflow: hidden;
    }

}