.our-community {
    height: 100vh;

    &-container {
        margin: 0 auto;
        max-width: 1300px;
        width: 100%;
        position: relative;
      
        .box {
            background: linear-gradient(359.66deg, #FC8A79 2.8%, rgba(28, 37, 61, 0) 80.44%);
            border-radius: 0px 0px 30px 30px;
            max-width: 489.35px;
            padding: 97.03px 20px 84.14px 38.62px;
            max-width: 417px;
            max-height: 477.83px;
            width: 100%;
            z-index: 9;
            position: relative;
            margin-bottom: 74.5px;
      
            @include breakpoint('slg_down') {
                padding: 52.34px 47.13px 46.3px 21.73px;
                max-width: 264.17px;
                max-height: 287.36px;
            }
            @include breakpoint('xs_down') {
                padding: 41.29px 33.15px 22.19px 30.22px;
                height: 350.02px;
                max-width: 350px;
                width: unset;
            }
            .header-text {
                margin-bottom: 26.29px;
                color: var(--color-secondary);
                @include breakpoint('slg_down') {
                    font-size: 26px;
                    line-height: 33px;
                }
                @include breakpoint('xs_down') {
                    font-size: 30px;
                    line-height: 38px;
                }
            
            }

            .description {
                color: var(--color-white);
                max-width: 360.13px;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.02em;
                @include breakpoint('slg_down') {
                    font-size: 12px;
                    line-height: 15px;
                    width: 264.31px;
                }
                @include breakpoint('xs_down') {
                    font-size: 14px;
                    line-height: 18px;
                    width: unset;
                    max-width: 286.62px;
                }
            }
        }

        .our-community-img {
            position: absolute;
            bottom: -74.5px;
            right: 0;
            z-index: 10;
            max-width: 680.52px;
            max-height: 478.86px;

            @include breakpoint('slg_down') {
                width: 382.93px;
                height: 273.45px;
                right: 38.85px;
            }
            @include breakpoint('xs_down') {
                width: 371.69px;
                height: 261.55px;
                position: relative;
                top: unset;
                left: unset;
                right: unset;
                bottom: unset;  
                transform: translateX(-20px);
            }
        }
    }

    &-wrapper {
        background: linear-gradient(0deg, #0D1A32 14.72%, #293856 96.68%);
        position: relative;
        overflow: hidden;
        @include breakpoint('xlg_down') {
            padding: 0 51px;
            }
            @include breakpoint('slg_down') {
              padding: 0 40px;
            }
            @include breakpoint('xs_down') {
              padding: 0 30px;
            }
    }
}