.thc-missionaries {
    height: 100vh;

    &-container {
        margin: 0 auto;
        max-width: 1300px;
        width: 100%;
        padding-top: 65.87px;
        min-height: 600px;  

        @include breakpoint('slg_down') {
            min-height: 459.84px;
            padding: 65.87px  40px 0 65.87px;
        }

        @include breakpoint('xs_down') {
            padding: 45.5px 0 0 0;
        }

        .box {
            background: linear-gradient(180deg, #8DCF9F 2.23%, rgba(28, 37, 61, 0) 100%);
            mix-blend-mode: normal;
            border-radius: 20px;
            max-width: 525.53px;
            height: 487.33px;
            padding: 39.58px 64px 14px 46.88px;
            width: 100%;
            z-index: 9;
            position: relative;

            @include breakpoint('slg_down') {
                max-width: 336.23px;
                height: 320.13px;
                padding: 26px 28px 14px 30.79px;
            }

            @include breakpoint('xs_down') {
                max-width: 274px;
                height: 390.72px;
                padding: 44.86px 22px 10.3px 36.45px;
            }

            .header-text {
                margin-bottom: 25.21px;
                color: #1C2942;

                @include breakpoint('slg_down') {
                    font-size: 26.2764px;
                    line-height: 33px;
                    margin-bottom: 14.4px;
                }

                @include breakpoint('xs_down') {
                    font-size: 30px;
                    line-height: 38px;
                }
            }

            .description {
                color: var(--color-white);
                width: 525.27px;
                line-height: 26px;
                letter-spacing: 0.02em;
                font-family: 'Outfit';

                @include breakpoint('slg_down') {
                    width: 277.43px;
                    font-size: 15px;
                    line-height: 15px;

                }

                @include breakpoint('xs_down') {
                    font-size: 14px;
                    line-height: 18px;
                    width: 272.56px;
                }
            }
        }

        .thc-missionaries-img {
            position: absolute;
            bottom: 47px;
            left: 48%;
            z-index: 8;
            transform: translateX(0);

            @include breakpoint('slg_down') {
                width: 629.64px;
                height: 369.59px;
                bottom: 67px;
                left: 28%;
                z-index: 8;
            }

            @include breakpoint('xs_down') {
                min-width: 512.71px;
                min-height: 408.48px;
                left: -55px;
                bottom: -30px;
            }

        }
    }

    &-wrapper {
        background: linear-gradient(180deg, #0D1A32 14.72%, #293856 96.68%);
        position: relative;
        min-height: 700px;
        overflow: hidden;
        @include breakpoint('xlg_down') {
            padding: 0 51px;
            }
        @include breakpoint('slg_down') {
            padding: 0  40px;
            min-height: 459.84px;
        }

        @include breakpoint('xs_down') {
            min-height: 735px;
            padding: 0 30px;
        }

        .bottom-line {
            height: 105px;
            background: linear-gradient(180deg, #0D1A32 -25.78%, #293856 96.68%);

            @include breakpoint('xlg_down') {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }
}