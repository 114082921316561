.start_legend {
    display: flex;
    gap: 46.5px;
    margin-top: 43.63px;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    @include breakpoint('lg_down') {
       gap: 0;
       margin-top: 48.02px;
    }

    @include breakpoint('md_down') {
        margin-top: 7.93px;
     }
    &-content {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        align-items: center;
        @include breakpoint('lg_down') {
           max-width: 787.22px;
           margin: auto;
          }
          @include breakpoint('md_down') {
            max-width: 330px;
         }

            .header-text {
                color: var(--color-green); 
                min-height: 75.58px;
                text-align: center;
                margin-top: 57.43px;
                margin-bottom: 7.37px;
                @include breakpoint('lg_down') {
                    margin-top: 0;
                    margin-bottom: 24px;
                }
                @include breakpoint('md_down') {
                    min-height: 88.9px;
                }
            }
            .description {
                color: var(--color-white);
                text-align: center;
                margin-bottom: 5.76px;
                min-height: 84.71px;
                margin: auto;
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 38px;
                @include breakpoint('lg_down') {
                    margin-bottom: 48px;
                }
                @include breakpoint('md_down') {
                    font-size: 22px;
                    line-height: 28px;
                    text-align: center;
                    margin-bottom: 55.1px;
                 }
            }
    }

    &-section {
        height: 905px;
        @include breakpoint('lg_down') {
            height: 825px;
          }

          @include breakpoint('md_down') {
            height: 729.49px;
        }
        .mini-title {
            margin-top: 39.17px;
        }
    }

    .btn {
        background: #8DCF9F;
        border-radius: 40px;
        color:var(--color-white);
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        width: 198px;
        height: 56px;
    }

    .image-wrapper {
        .legend-tree {
            margin-left: auto;
            @include breakpoint('lg_down') {
                width: 107.86px;
                height: 128.95px;
                margin-top: -68px;
                margin-right: 69.48px;
              }
              @include breakpoint('md_down') {
               margin: 67px 0 0 201px;
             }

        }
    }

        .bar-blue {
        background-image: url("../../img/home/bar-blue.svg");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -42px;
        height: 351.06px;
        z-index: 9;

        @include breakpoint('lg_down') {
            background-image: url("../../img/home/bar-blue-tablet.svg");
            bottom: -2px;
          }
          @include breakpoint('md_down') {
            background-image: url("../../img/home/bar-blue-mobile.svg");
            left: -80px;
            height: 177.42px;
          }
    }

    &-wrapper {
        position: relative;
    }
}