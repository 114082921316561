.introduction {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    align-items: center;
    height: 100%;
    
    @include breakpoint('lg_down') {
        grid-template-columns: auto;
        margin: auto;
    } 
    
    &-image {
        @include breakpoint('lg_down') {
           order: 2;
        } 
        .people-hugging {
            position: absolute;
            z-index: 3;
            top: 50%;
            transform: translateY(-50%);
            @include breakpoint('lg_down') {
                position: relative;
                transform: unset;
                top: unset;
                margin-left: -58px;
            } 
            @include breakpoint('sm_down') {
                margin: 0 auto;
             }
        }
    }

    &-content {
        @include breakpoint('xs_down') {
            max-width: 350px;
            margin: 0 auto;
        }
        &-description {  
            position: absolute;
            z-index: 3;
            top: 50%;
            transform: translateY(-50%);   
            margin: 0;
            @include breakpoint('lg_down') {
                max-width: 548px;
                position: relative;
                transform: unset;
                top: unset;
                text-align: center;
            }   
            @include breakpoint('xs_down') {
                max-width: 350px;
                margin: 0 auto;
            }     
            .header-text {
                color: var(--color-yellow);
                min-height: 63px;
                margin-bottom: 31px;
                @include breakpoint('xs_down') {
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 50px;
                } 
            }
            .description {
                color: var(--color-yellow);
                max-width: 547.77px;
                line-height: 26px;  
                margin-bottom: 48px;
             
                @include breakpoint('xs_down') {
                    font-size: 18px;
                    line-height: 23px;
                    margin-bottom: 48px;
                    max-width: 346.9px;
                    margin-left: auto;
                    margin-right: auto;
                } 
            }

            .btn {
                border-radius: 40px;
                width: 168px;
                height: 56px;
                border-color: var(--color-yellow); 
                color: var(--color-yellow);
                @include breakpoint('lg_down') {
                    margin: 0 auto 50.93px auto;
                }

                @include breakpoint('xs_down') {
                    margin: 0 auto;
                } 
             
            }
        }
    }



   &-section {
   height: 890px;
   @include breakpoint('lg_down') {
    height: 1105px;
} 
    .mini-title {
        margin-top: 43.63px;
    }

    .next-section {
        background-color: #2A3756;
    }

    .bar {
        background-image: url("../../img/home/bar-bottom.svg");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-position: bottom;
        height: 174.55px;
        @include breakpoint('xs_down') {
            height: 108px;
        }
    }
   }

   &-wrapper { 
    position: relative;
    background: linear-gradient(180deg, #101D38 0%, #171F2F 21.41%);
    overflow: hidden;
   }
}

.bottom-shape-img {
    position: relative;
    z-index: 99;
    height: 112.84px;
    min-width: 798.91px;
    @include breakpoint('lg_down') {
        margin: 0 auto;
     }
}

.shape-wrapper {
    max-width: 1350px;
    margin: auto;
    @include breakpoint('lg_down') {
        margin-left: 110px;
    }
    @include breakpoint('md_down') {
        margin-left: 50px;
     }
     @include breakpoint('sm_down') {
        margin: 0 auto;
     }
}

.back-clip-path {
    clip-path: polygon(0 55%, 100% 0, 100% 100%, 0% 100%);
    background: #354466;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @include breakpoint('xs_down') {
        clip-path: polygon(0 41%, 100% 14%, 100% 100%, 0% 100%);

     }
}

.bee-shadow-img {
    width: 158.51px;
    height: 242.41px; 
    margin-left: auto;
    margin-bottom: 66.79px;
    z-index: 99;
    margin-right: 150px; 
    @include breakpoint('lg_down') {
        margin-top: -110px;
        margin-right: 90px;
    } 

    @include breakpoint('xs_down') {
        width: 95.15px;
height: 145.61px;
margin-right: 45.92px;
margin-top: -60px;
    }
}