.decentralized {
  height: 100vh;

  &-container {
    margin: 0 auto;
    max-width: 1300px;
    width: 100%;
    text-align: center;
  }

  &-wrapper {
    background: linear-gradient(180deg, #0D1A32 14.72%, #293856 96.68%);
    overflow: hidden;
    position: relative;
    padding-bottom: 48.03px;
    @include breakpoint('xlg_down') {
      padding: 0 51px 48.03px 51px;
      }
      @include breakpoint('slg_down') {
        padding: 0 40px 29.35px 40px;
      }
      @include breakpoint('xs_down') {
        padding: 0 30px 42.18px 30px;
      }

    .columns {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(640px, 1fr));
      grid-column-gap: 20px;
      max-width: 1300px;
      margin: 9.91px auto 0 auto;
      min-width: 100%;
      grid-template-rows: auto;

      @include breakpoint('xlg_down') {
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      }

      @include breakpoint('xs_down') {
        grid-template-columns: 1fr;
        margin: 0 auto 0 auto;
      }

      .column-txt-img {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include breakpoint('lg_down') {
         gap: 24px;
         height: 100%;
        }
        @include breakpoint('slg_down') {
          height: 100%;
         }
        @include breakpoint('xs_down') {
          flex-direction: column;
          display: flex;
          gap: 24px;
        }
      }

      .column {
        max-height: 500px;
        background: linear-gradient(0deg, #8DCF9F 2.23%, rgba(28, 37, 61, 0) 100%);
        border-radius: 0px 0px 20px 20px;
        display: flex;
        flex-direction: column;
        padding: 61.97px 0 34.49px 34.68px;

        @include breakpoint('slg_down') {
          padding: 32.86px 0 22.7px 17.74px;
          max-height: 296.5px;
        }
        @include breakpoint('xs_down') {
          padding: 29.67px 0 34.41px 22.89px;
          max-height: unset;
        }


        &-list {
          margin-bottom: 25.24px;
          @include breakpoint('slg_down') {
            margin-bottom: 16px;
          }
          li {
            display: flex;
            gap: 20.12px;
            margin-bottom: 4.36px;

            img {
              width: 16.58px;
              height: 16.46px;

              @include breakpoint('slg_down') {
                width: 13.72px;
                height: 15.43px;
              }
            }
          }
        }

        &-text {
          font-family: 'Outfit';
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.02em;
          color: var(--color-white);
          text-align: left;
          width: 257.91px;


          @include breakpoint('slg_down') {
            font-size: 12px;
            line-height: 15px;
            max-width: 142.57px;
          }

          @include breakpoint('xs_down') {
            font-size: 14px;
            line-height: 18px;
            max-width: unset;
            padding-right: 21px;
          }
        }

        .problem-people-img {
          padding-right: 39px;
      
          @include breakpoint('slg_down') {
            max-width: 240px;
            padding-right: 18.68px;
            margin-top: auto;
          }
          @include breakpoint('slg_down') {
            max-width: 141.66px;
            max-height: 127.26px;
            padding-right: 18.68px;
          }

          @include breakpoint('xs_down') {
            max-width: unset;
            max-height: unset;
            margin-top: 16px;
          }
        }

        .solution-people-img {
    
          @include breakpoint('slg_down') {
            max-width: 160px;
            max-height: 126px;
            margin-top: auto;
          }

          @include breakpoint('xs_down') {
            max-width: unset;
            max-height: unset;
            margin-top: 16px;
          }
        }

        .problem-img,
        .solution-img {
          width: 445.58px;
          height: 152.05px;
          margin: 0 auto 56px auto;
    
          @include breakpoint('slg_down') {
            width: 190.36px;
            height: 49.73px;
            margin: 0 auto 30.06px auto;
          }

          @include breakpoint('xs_down') {
            width: 251.41px;
            height: 65.67px;
          }
        }
      }
    }

    .header-text {
      color: var(--color-green);
      margin-bottom: 28.03px;
      padding-top: 57.86px;

      @include breakpoint('xlg_down') {
        margin-bottom: 43.23px;
      }
      @include breakpoint('xs_down') {
        margin-bottom: 21.75px;
        padding-top: 35.35px;
      }
    }

    .description {
      @include breakpoint('xs_down') {
        font-size: 14px;
        line-height: 18px;
        width: 304.72px;
      }
    }

    .how-we-do-it-wrapper {
      position: relative;

      .header-text {
        color: var(--color-green);
        margin-bottom: 28.86px;
        padding-top: 31.06px;

        @include breakpoint('slg_down') {
         padding-top: 15.31px;
         margin-bottom: 0;
        }

        @include breakpoint('xs_down') {
          padding-top: 37.97px;
          margin-bottom: 9.35px;
        }
      }

      .columns-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(637px, 1fr));
        grid-column-gap: 20.23px;
        grid-row-gap: 20.29px;
        max-width: 1300px;
        margin: 0 auto;
        min-width: 100%;
        grid-template-rows: auto;
        z-index: 2;
        position: relative;
        @include breakpoint('xlg_down') {
          grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
        }
        @include breakpoint('md_down') {
          grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        }
    

        @include breakpoint('xs_down') {
          grid-template-columns: 1fr;
          grid-row-gap: 25.93px;
        }

        .column {
          background: linear-gradient(0deg, #0D1A32 0%, rgba(13, 26, 50, 0) 100%);
          border-radius: 20px;
          display: flex;
          gap: 62.89px;
          padding: 36.55px 46.62px 40px 44.87px;
          max-height: 230px;

          @include breakpoint('md_down') {
           gap:33.35px;
          }
          @include breakpoint('slg_down') {
            padding: 21.95px 24.72px 27.17px 23.79px;
          }
          @include breakpoint('xs_down') {
            gap: 13.92px;
            padding:23.08px 21.82px 40px 14.16px;
           }

          img {
            width: 120px;
            height: 120px;

            @include breakpoint('slg_down') {
              width: 63.63px;
              height: 63.63px;
            }

            @include breakpoint('xs_down') {
              width: 60px;
              height: 60px;
            }
          }

          &-text-wrapper {
            text-align: left;

            h2 {
              font-family: 'Outfit';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 30px;
              height: 55.56px;
              color: var(--color-white);

              @include breakpoint('slg_down') {
                font-size: 12.7259px;
                line-height: 16px;
                height: 29.46px;
              }
              @include breakpoint('xs_down') {
                font-size: 18px;
                line-height: 23px;
                height: 33.95px;
              }
            }

            p {
              font-family: 'Outfit';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0.02em;
              color: var(--color-greener);

              @include breakpoint('slg_down') {
                font-size: 12px;
line-height: 15px;
letter-spacing: 0.02em;
              }
              @include breakpoint('xs_down') {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.02em;
              }
            }
          }

        }
      }
    }

    .bottom-shape {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      img {
        width: 100%;
        @include breakpoint('slg_down') {
          height: 56.5px;
        }
        @include breakpoint('xs_down') {
          height: auto;
        }
      }

      .shaped2 {
        @include breakpoint('xs_down') {
          display: none;
        }
      }

      .shaped2mobile {
        display: none;

        @include breakpoint('xs_down') {
          display: block;
        }
      }

      @include breakpoint('xlg_down') {
        bottom: 0;
      }
    }
  }
}