.signup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  align-items: center;
  height: 100%;

  &-image {
    position: relative;
    @include display(flex, flex-end);
    height: 100%;
    margin-top: -170px;

    .man-work {
      z-index: 2;
      max-width: 540px;
    }

    &.position {
      margin-top: initial;
    }
  }

  &-content {
    &-description {
      h5 {
        margin-bottom: 60px;
        max-width: 500px;
        font-weight: 400;
        font-family: 'Proxima Nova';
        line-height: 28px;
      }

      h4 {
        font-weight: 700;
        margin-bottom: 40px;
      }

      h2 {
        font-weight: 600;
        margin-bottom: 30px;
      }
    }

    &-select {
      width: 100%;
      max-width: 405px;
    }
  }

  h6 {
    font-family: 16px;
  }
}

.next-section-signup {
  width: 100%;
  padding: 30px 0;
  @include position (absolute, initial, initial, 0, 0);

  div {
    &:first-child {
      visibility: hidden;
    }
  }

  &-inner {
    @include display(flex, center, space-between, initial);
    max-width: 1140px;
    margin: 0 auto;
    h3 {
      font-family: $font-stack;
      font-weight: 600;
    }
  }

  span {
    &.desc-text {
      font-size: 22px;
    }
  }
}


// Singup Getstarted //
.signup-gs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  align-items: center;
  height: 100%;

  &-image {
    position: relative;
    @include display(flex, flex-end);
    height: 100%;
    margin-top: -170px;

    .women-speach {
      z-index: 2;
      max-width: 630px;
    }

    &.position {
      margin-top: initial;
    }
  }

  &-content {
    &-description {
      h5 {
        margin-bottom: 60px;
        max-width: 500px;
        font-weight: 400;
        font-family: 'Proxima Nova';
      }

      h4 {
        font-weight: 700;
        margin-bottom: 40px;
      }

      h2 {
        font-weight: 600;
        margin-bottom: 30px;
      }
    }

    &-select {
      width: 100%;
      max-width: 405px;
    }
  }
}
