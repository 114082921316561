.how-it-works {
    display: flex;
    gap: 46.5px;
    margin-top: 20px;
    @include breakpoint('md_down') {
        flex-direction: column-reverse;
        margin: 0;
        height: 100%;
        overflow-x: hidden;
    }
    &-image {
        position: relative;
        @include display(flex, flex-end);
        z-index: 9;
      
        .phones-img {
            position: absolute;
            bottom: -41.76px;
            right: -51.81px;
            z-index: 99;
            @include breakpoint('lg_down') {
                width: 437.32px;
                height: 465.26px;
                bottom: 0;
                right: 0;
              }

              @include breakpoint('md_down') {
                width: 389.87px;
                height: 414.78px;
                left: 26.18px;
                bottom: 70px;
              }
        }

        .stars-img {
            @include breakpoint('lg_down') {
               display: none;
            }  
        }
        .stars-img-bg-tablet {
            display: none;
            @include breakpoint('lg_down') {
               display: block;
               bottom: 135px;
            }  
            @include breakpoint('md_down') {
                display: none;
             }  
        }

     

      
    }
    .stars-img-bg-mobile {
        display: none;
        @include breakpoint('md_down') {
            display: block;
            position: absolute;
            bottom: 25px;
         }  
    }
    &-content {
        @include breakpoint('lg_down') {
          max-width: 426.87px;
        }
        @include breakpoint('md_down') {
            margin: 1.53px 0 auto 29px;
            display: flex;
            flex-direction: column;
            max-width: 320.43px;
            
        }
            .header-text {
                color: #35476A;
                min-height: 126px;
                text-align: left;
                margin-top: 91.73px;
                margin-bottom: 30px;
                @include breakpoint('md_down') {
                    min-height:76px;
                    margin-top: 0;
                }
            }
            .description {
                color: #35476A;
                text-align: left;
                margin-bottom: 40px;
                max-width: 609.37px;
                min-height: 286px;
                @include breakpoint('lg_down') {
                   margin-bottom: 107px;
                 }
                 @include breakpoint('md_down') {
                    font-size: 18px;
                    line-height: 23px;
                    width: 320.43px;
                    margin-bottom: 30px;
                }
            }
    }

    &-section {
        min-height: 905px;
        @include breakpoint('lg_down') {
            height: 1105px;
            min-height: unset;
        }
        .mini-title {
            margin-top: 40.55px;
            color: #99E2AD;
            @include breakpoint('lg_down') {
                margin-top: 39.5px;
            }
        }
    }

    .boxes-wrapper {
        display: grid;
        grid-template-columns: repeat(2,  399.27px) 402.76px;
        grid-auto-rows: minmax(100px,  285.1px);
        gap: 46px;
        margin: auto;

        .box {
            border-radius: 20px;
            padding: 30.03px 20px 0 48.35px;

            &-blue {
              background-color: var(--color-blue-light);
              border: 1px solid #88CBDE;
            }

            &-red {
                background-color: #D7807A;
                border: 1px solid #E2857E;
                padding-left: 39.56px;
            }
            
            &-green {
                background: var(--color-green);
                border: 1px solid var(--color-green);
                padding-left: 41.11px;
            }

            &-title {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 45px;
                color: var(--color-white);
                height: 72.79px;
                margin-bottom: 7.11px;
                text-align: left;
            }

            &-description {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 28px;
                color: #2A3756;
                max-width: 317.45px;
                text-align: left;
            }
        }
    }
    .btn {
        color: #31446A;
        border-color: #31446A;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        min-width: 205.03px;
        height: 44px;
        @include breakpoint('md_down') {
           margin-left: auto;
           z-index: 9;
           position: relative;
           width: 168px;
           min-width: auto;
        }
    }

    &-wrapper {
        position: relative;
    }

}