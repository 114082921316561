.join-our-communities {
    &-img {
        margin-top: 31.81px;
        z-index: 99;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include breakpoint('md_down') {
            padding: 0 88px;
        }

        @include breakpoint('slg_down') {
            padding: 0 33px;
        }

        .header-text {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 50px;
            line-height: 63px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #81C394;
            margin-top: 61px;
            margin-bottom:60px;

            @include breakpoint('lg_down') {
                font-size: 40px;
                line-height: 50px;
                margin-bottom: 105.04px;
                margin-top: 246.34px;
                white-space: unset;
                text-align: center;
            }
            @include breakpoint('slg_down') {
                font-size: 26px;
                line-height: 33px;
                margin-bottom: 49.54px;
                margin-top: 33px;
                white-space: unset;
                text-align: center;
            }
            @include breakpoint('xs_down') {
                font-weight: 600;
                font-size: 30px;
                line-height: 38px;
                margin-bottom: 84.59px;
                margin-top: 131.48px;
                white-space: unset;
                text-align: center;
            }
        }

        .description {
            color: var(--color-white);
            text-align: center;

            @include breakpoint('slg_down') {
                font-size: 18px;
                line-height: 23px;
                text-align: center;
                padding: 0 10px;
            }
        }
    }

    &-section {
        padding: 0;
        height: 487px;
        @include breakpoint('lg_down') {
           min-height: 823.41px;
        }
        @include breakpoint('slg_down') {
            height: 210px;
            min-height: unset;
        
         }
        @include breakpoint('xs_down') {
            min-height: unset;
            height: 506.33px;
         }
        .mini-title {
            min-height: 506.33px;
        }
    }


    &-wrapper {
        position: relative;
        overflow: hidden;
        background: linear-gradient(180deg, #080B11 0%, #171F2F 83.9%);
        padding-bottom: 2px;
        @include breakpoint('lg_up') {
            max-height: 381.42px;
        }
       
    }

    .join-box-wrapper {
        display: flex;
        gap: 20px;
        max-width: 980px;
        margin-top: 64px;
        justify-content: center;
        margin: auto;

        @include breakpoint('xlg_down') {
            padding: 0 38px;
        }

        @include breakpoint('lg_down') {
            padding: 0 105.23px;
            gap: 24.67px;
        }

        @include breakpoint('md_down') {
            padding: 0 68px;
        }

        @include breakpoint('xs_down') {
            flex-direction: column;
            align-items: center;
            gap: 27.48px;
            padding: 0 33px;
            margin-top: 0;
        }

        .box {
            height:112px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 22.9012px;
            cursor: pointer;
            z-index: 999;
            flex: 1;

            @include breakpoint('lg_down') {
                padding: 0 24px;
                width: 412px;
                min-height: 112px;
            }

            @include breakpoint('slg_down') {
                max-width: 161.51px;
                height: 43.95px;
                min-height: unset;
                border-radius: 10.9868px;
            }
            @include breakpoint('xs_down') {
                min-width: 253.34px;
                border-radius: 14.0823px;
                height: 68.87px;
                width: 253.34px;
                min-height: 68.87px;
            }

            h6 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 34px;
                display: flex;
                align-items: center;
                letter-spacing: -0.01em;
                color: #31446A;

                @include breakpoint('slg_down') {
                    font-size: 10px;
                    line-height: 19px;
                }
                @include breakpoint('xs_down') {
                    font-size: 18px;
                    line-height: 21px;
                }
            }

            &--red {
                background-color: var(--color-secondary);
            }

            &--green {
                background-color: var(--color-green);
            }

            &--blue {
                background-color: var(--color-blue-light);
            }

            .discord-icon {
                margin-bottom: 28.12px;
                width: 84.14px;
                height: 65.63px;

                @include breakpoint('slg_down') {
                    margin: 0 27.81px 0 0;
                    width: 60px;
                    height: 43.8px;
                }
                
            }

            .twitter-icon {
                width: 58px;
                height: 47px;
                margin-right: 44.02px;
                @include breakpoint('slg_down') {
                    width: 20.33px;
                    height: 16.48px;
                    margin-right: 11.26px;
                }
                @include breakpoint('xs_down') {
                    width: 35.67px;
                    height: 28.9px;
                    margin-right: 17px;
                }
            }
            .msg-icon {
                width: 66px;
                height: 45px;
                margin-right: 50.96px;
                @include breakpoint('slg_down') {
                    width: 23.07px;
                    height: 15.93px;
                    margin-right: 13.7px;
                }
                @include breakpoint('xs_down') {
                    width: 40.58px;
                    height: 27.67px;
                    margin-right: 15.9px;
                }
            }

            .partner-icon {
                margin-bottom: 16.93px;

                @include breakpoint('slg_down') {
                    margin: 0 31.76px 0;
                    width: 50px;
                    height: 57.43px;
                }
            }
        }
    }
}

.bg-stars {
    position: absolute;
    left: 0;
    right: 0;
   margin: auto;
   @include breakpoint('slg_down') {
    max-height: 202px;
}
    @include breakpoint('xs_down') {
        min-width: 620px;
    }
}

.path-bottom-shaped {
    clip-path: polygon(40% 9%, 80% 0, 100% 4%, 100% 70%, 100% 100%, 0 98%, 0% 70%, 0 12%);
    height: 342.97px;
    background: #1A263E;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    @include breakpoint('slg_down') {
        display: none;
    }
}


.join-footer-wrapper {
    margin-top: 120.25px;

    .truck-in-road {
        z-index: 9999;
        position: relative;
        margin-top: 10px;
        
        @include breakpoint('slg_down') {
            display: none;
        }
    }

    .truck-in-road-mobile {
        z-index: 9999;
        position: relative;
       display: none;
        @include breakpoint('slg_down') {
            display: block;
            margin-top: 10px;
        }
    }
    .bg-footer-linear {
        background: linear-gradient(180deg, #1A263E 0%, #293856 36.78%);
        height: 466.95px;
        position: absolute;
        bottom: -128px;
        width: 100%;
        left: 0;
        right: 0;
        @include breakpoint('slg_down') {
            bottom: -264px;
            z-index: 9;
        }
    }
    .mobile-shape-bottom {
        display: none;
        @include breakpoint('slg_down') {
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}