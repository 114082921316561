.footer {
  min-height: 145.84px;
 padding: 0  63.53px;
  @include display(flex, center, space-between, initial);

  @include breakpoint('xs_down') {
    min-height: 85px;
  }

  img {
    @include breakpoint('xs_down') {
    width: 137.74px;
    height: 29.17px;
    }
  }
  @include breakpoint('lg_down') {
    align-items: center;
    padding: 0 43.53px  0 41.18px;
    margin-top: auto;
    justify-content: space-between;
    gap: 79.66px;
    overflow-x: hidden;
}

@include breakpoint('xs_down') {
  padding: 0 31.5px 0 29.77px;
}

  .primary {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    @include breakpoint('lg_down') {
      margin: 0 auto;
    }
    @include breakpoint('xs_down') {
      font-size: 9.83863px;
      line-height: 21px;
      white-space: nowrap;
      }
  }

  &-social-media {
    &-icon {
      margin-left: 15px;

      svg,
      circle {  
        transition: .25s all ease-in-out;
        cursor: pointer;
      }
      
      &:hover {
        svg,
        circle {
          fill: var(--color-green);
          stroke: var(--color-green);
        }
      }
    }
  }

  &-right {
    display: flex;
    align-items: center;
    gap: 41.7px;
    @include breakpoint('lg_down') {
      width: 100%;
    }
    @include breakpoint('sm_down') {
      flex-direction: column-reverse;
    justify-content: center;
    gap: 31px;
    }
  }

  &-wrapper {
    background: #0E1629; 
   overflow: hidden;
   .container {
    height: 139px;
    @include breakpoint('xs_down') {
      height: 85px;
    }
   }
  }
}
