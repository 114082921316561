.communities {
    &-inner {
      @include display(flex, initial, center, column);
      height: 100%;
    }

    &-description {
      margin: 0 40px 50px;
  
      h3 {
        font-size: 40px;
        color: var(--color-navy);
        font-weight: 600;
        line-height: 68px;
      }
  
      p {
        font-size: 22px;
        color: var(--color-navy);
        font-family: "Proxima Nova";
      }
    }
  
    &-card {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-end;
      z-index: 3;
      background-color: var(--color-pink);
      align-items: center;
      border-radius: 0 0 0 40px;
      @media (min-width: 1350px) {
        min-height: 572px;
      }
  
      &-left {
        @include display(flex, center, center, column);
        border-radius: 0 0 0 40px;
        padding: 70px;
        @media (min-width: 1350px) {
          min-height: 572px;
          padding: 0 70px;
        }
  
        p {
          font-family: "Proxima Nova";
          text-align: center;
          margin-bottom: 76px;
          line-height: 24px;
        }

        @include breakpoint("xs_up") {
          h2 {
            text-align: center;
          }
        }

        @include breakpoint("xs_up") {
          h2 {
            text-align: center;
          }
        }
      }
  
      &-right {
        @include display(flex, center, center);
        background-color: var(--color-pink);
        padding: 77px;
      }
    }
    .btn {
      min-height: 44px;
      font-size: 14px;
      line-height: 22px;
    }
  }
  
  // Card //
  .slick-slide > div {
    margin: 0 40px;
  }
  
  .slick-prev,
  .slick-next {
    &::before {
      content: none !important;
    }
  
    border: 18px solid transparent !important;
    border-left-color: var(--color-navy) !important;
  }
  .slick-prev {
    border-left-color: transparent !important;
    border-right-color: var(--color-navy) !important;
  }
  
  .card {
    z-index: 2;
  
    &-box {
      &-description {
        background-color: var(--color-white);
        border-radius: 0 0 0 60px;
        padding: 35px;
  
        h4 {
          font-size: 28px;
          line-height: 40px;
          color: var(--color-primary);
        }
  
        p {
          font-size: 18px;
          line-height: 24px;
          color: var(--color-primary);
          margin: 20px 0;
        }
      }
    }
  }
  