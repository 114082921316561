.connect-bg {
    height: 100%;

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, #79B589 0%, #8DCF9F 26.2%);
        top: 100px;
    }
}

.connect {
    @include display(flex, center, center, initial);
    height: 100%;
    position: relative;
    
    &-image {
        @include display(flex, flex-end);
        height: 100%;
        margin-top: -100px;

        img {
            max-width: 920px;
            max-height: calc(100% - 180px);
            z-index: 2;
        }
    }

    &-content {
        @include display(flex, center, center, initial);
        height: 100%;
        z-index: 2;
        position: absolute;
        top: -10vh;
        left: 60px;
    }
}