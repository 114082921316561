.jobs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    align-items: center;
    height: 100%;
    
    &-image {
        position: relative;
        @include display(flex, flex-start);
        height: 100%;
        margin-top: -75px;
        
        .girl-fluctuated {
            z-index: 2;
            max-width: 500px;
        }
    }

    &-content {        
        &-description {
            h5 {
                max-width: 470px;
                font-family: 'Proxima Nova';
                line-height: 28px;
                font-size: 22px;
            }
        }

        .btn {
            width: 139px;
            min-height: 44px;
            padding: 0;
        }
    }

    &-img-bottom {
        position: absolute;
        @include position(absolute, initial, initial, 20px, initial);
        width: 100%;
        max-width: 800px;
        @include display(flex, initial, center, initial);
        margin: 0 auto;
        z-index: 3;
    }
}