.our-impact {
    display: flex;
    height: 100%;

    @include breakpoint('lg_down') {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 90px;
    }

    @include breakpoint('xs_down') {
        gap: 20px;
    }

    &-img {
        margin-top: 31.81px;
        z-index: 99;

        @include breakpoint('md_down') {
            margin-top: 79.81px;
        }

        @include breakpoint('sm_down') {
            margin-top: auto;
            margin-bottom: 77px;
        }

        @include breakpoint('xs_down') {
            margin-top: -35px;
            margin-bottom: 0;
        }

        .truck-img {
            @include breakpoint('md_down') {
                width: 700px;
            }

            @include breakpoint('xs_down') {
                display: none;
            }
        }

        .truck-mobile-img {
            display: none;

            @include breakpoint('xs_down') {
                display: block;
            }
        }
    }

    &-content {

        margin-top: 63.39px;

        @include breakpoint('xs_down') {
            z-index: 999;

        }

        .header-text {
            color: var(--color-green);
            text-align: left;
            margin-bottom: 30px;
            white-space: nowrap;

            @include breakpoint('lg_down') {
                text-align: center;
            }
        }

        .description {
            color: var(--color-green);
            text-align: left;
            max-width: 449px;

            @include breakpoint('lg_down') {
                text-align: center;
                max-width: 708px;
            }

            @include breakpoint('sm_down') {
                padding: 0 38px;
            }
            @include breakpoint('xs_down') {
                font-size: 18px;
                line-height: 26px;
            }
        }

        .btn-missionaries-tablet {
            display: none;

            @include breakpoint('lg_down') {
                display: flex;
                width: 364px;
                height: 56px;
                color: #26385C;
                border-radius: 40px;
                margin: 48px auto 0 auto;
            }

            @include breakpoint('xs_down') {
                width: 155px;
                height: 81px;
                padding: 0 81px;
                line-height: 1.2;
            }
        }
    }

    &-section {
        margin-bottom: -180px;
        height: 1060px;

        @include breakpoint('lg_down') {
            margin-bottom: 0;
            height: 1485px;
        }

        @include breakpoint('md_down') {
            height: 1370px;
        }

        @include breakpoint('xs_down') {
            height: 1146px;
        }

        .mini-title {
            margin-top: 44.95px;
        }
    }


    &-wrapper {
        position: relative;
        overflow: hidden;

        .next-section {
            background: #354466;
        }
    }
}

.btn-bar-bottom {
    position: relative;
    width: 100%;
    z-index: 9;
    position: absolute;
    bottom: 105px;
    left: 0;
    right: 0;
    clip-path: polygon(28% 5%, 100% 11%, 100% 60%, 100% 100%, 0 100%, 0% 60%, 0 16%);
    background: #24304B;
    height: 199.32px;

    .container {
        height: 199.32px;
    }

    .btn {
        padding-left: 0;
        padding-right: 0;   
        width: 364px;
        height: 56px;
        color: #26385C;
        border-radius: 40px;
        margin: auto 0;
        position: absolute;
        line-height: 26px;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint('lg_down') {
            display: none;
        }
    }
}