.solution {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    align-items: center;
    min-height: 800px;
    height: 100%;

    @include breakpoint('md_down') {
        grid-template-columns: auto;
    }

    &-image {
        position: relative;
        @include display(flex, flex-end, center);
        height: 100%;
        z-index: 3;
        
        .superman-img {
           position: absolute;
           bottom: 48.35px;

           @include breakpoint('md_down') {
            width: 197.61px;
            height: 241.93px;
            right: 0;
            bottom: 145px;
            left: 227.35px;
           }
        }
    }

    .stars-img {
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        @include breakpoint('lg_down') {
            display: none;
         }

    }
    .stars-tablet-img {
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        display: none;
        @include breakpoint('lg_down') {
            display: block;
         }
         @include breakpoint('sm_down') {
            display: none;
         }
    }
    .stars-mobile-img {
        display: none;
        @include breakpoint('sm_down') {
            display: block;
            position: absolute;
            top: 19.49px;
         }
    }

    &-content {
        align-self: center;
        min-height: 405px;
        z-index: 9;
        @include breakpoint('md_down') {
           z-index: 9;
        }
        &-description {
            @include breakpoint('lg_down') {
               margin-left: 50.26px;
               max-width: 427.47px;
            }

            @include breakpoint('md_down') {
                margin: 50px 0 0 29.99px;
            }
            .header-text {
                color: var(--color-secondary);
                min-height: 63px;
                margin-bottom: 30px ;
                @include breakpoint('md_down') {
                    font-size: 30px;
                    line-height: 38px;
                    min-height: 38px;
                }
            }
            .description {
                color: var(--color-secondary);
                max-width: 581px;
                height: 151.2px;
                margin-bottom: 40px;
                @include breakpoint('lg_down') {
                    margin-bottom: 83px;
                    height: auto;
                 }
                 @include breakpoint('md_down') {
                    font-size: 20px;
                    line-height: 26px;
                    max-width: 312.34px;
                }
            }
        }
    }

    &-section {
        min-height: 905px;
        @include breakpoint('lg_down') {
            height: 1105px;
        }
        
        @include breakpoint('md_down') {
            min-height: unset;
            height: 786.51px;
        }
        .mini-title {
            margin-top: 43.63px;
        }

     

        .bar {
            background-image: url("../../img/home/bar2.svg");
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 105px;
            height: 174.55px;
            .btn {
                color:  var(--color-secondary);
                border: 1px solid  var(--color-secondary);
                border-radius: 100px;
                font-family: 'Proxima Nova';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 18px;
                text-align: center;
                width: 254.91px;
                height: 44px;
                padding: 0;
                margin:  71.92px auto auto 0;
            }
        }
    }
    &-wrapper { 
        position: relative;
        margin-top: -1px;
        @include breakpoint('md_down') {
            overflow: hidden;
        }
       }
}