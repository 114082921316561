.articles {
    &-tab {
         &>div:first-child {
            height: 53px;
            margin-bottom: 65.74px;
            justify-content: center;

            @include breakpoint('md_down') {
                flex-direction: column;
                height: 115px;
                gap:0;
            }
            .tab {
                background-color: transparent;
                color: rgba(226, 133, 126, 1);
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 32px;
                text-align: center;
                letter-spacing: 0.02em;
                padding: 0!important;
                @include breakpoint('md_down') {
                    margin:0;
                }
            }

              &>div:nth-child(2) {
                    color: rgba(186, 231, 198, 1);
                }
           
                &>div:nth-child(3) {
                    color: rgba(253, 239, 163, 1);
                }
                &>div:nth-child(4) {
                    color: rgba(176, 223, 233, 1);
                }
        

            .tab-active {
                color: var(--color-white);
            }
         }

         .card {
            &-top {
                @include breakpoint('md_down') {
                    width: 100%;
                    height: 197px;
                    align-items:baseline;
                }
            }
            &-bottom {
                @include breakpoint('md_down') {
                    width: 100%;
                    max-height: 103px;
                    padding: 0;
                    max-width: unset;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    .card-title {
                        margin: 0;
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }
            &-description {
                @include breakpoint('md_down') {
                    display: none;
                }
            }
            .read-more {
                @include breakpoint('md_down') {
                    display: none;
                }
            }
         }
    }

    &-cards {
        max-width: 1300px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 58.89px;
        column-gap: 64.33px;
        @include breakpoint('lg_down') {
            grid-template-columns: repeat(2, 425px);
            justify-content: center;
            column-gap: 49.19px;
            row-gap: 51.74px;

            &>div:nth-child(odd) {
                margin-left: auto;
              }
              &>div:nth-child(even) {
                margin-right: auto;
              }
        }
        @include breakpoint('md_down') {
            grid-template-columns: auto;
            column-gap: 0;
            row-gap: 33px;
            padding-left: 30.35px;
            padding-right: 27.65px;
        }
    }

    &-wrapper {
        position: relative;
        max-height: unset !important;
        height: 100% !important;
        .container {
            height: auto;
        }
    }
    &-section {
        padding-top: 40.11px;
        .show-more {
            width: 176px;
            height: 44px;
            border: 1px solid #E9AAAB;
            border-radius: 30px;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            color: #D7807A;
            margin: 69.11px auto 55.11px auto;
        }

        .book-img-wrapper {
            position: relative;
            z-index: 9;
        }

        .book-img {
            margin: auto;
            margin-bottom: 21.34px;
            z-index: 9;
        }

        .read-more {
            background-color: transparent;
            font-family: 'Proxima Nova';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #3F5475;
            font-weight: 600;
            margin-top: 24px;
            cursor: pointer;
        }
    }


}
.suggested {
    &-section {
        .mini-title {
            margin-top: 38px;
            margin-bottom: 45px;
        }
    }

     &-content {
        margin-bottom: 69.79px;
        .header-text {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 50px;
            line-height: 78px;
            color: #E2857E;
        }
     }

     &-slider {
        position: relative;
        .slick-track {
            display: flex;
        }
       .slick-list {
        overflow: hidden;
       }
        .prev-arrow {
            display: block;
            position: absolute;
            top: 50%;
            left:-62.38px;
            cursor: pointer;
            z-index: 99;
        }
        .next-arrow {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            cursor: pointer;
            z-index: 99;
        img {
            transform: rotate(180deg)
        }
        }

        .slider-card {
            max-width: 388.65px;
            &-top {
               width: 100%;
                height: 245.46px;
                background: transparent;
                border-radius: 20px 20px 0px 0px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                background: #BAE7C6;
            }

            &-bottom {
                height: 142.84px;
                background: #FFFFFF;
                border-radius: 0px 0px 20px 20px;
                padding: 38.77px 47.36px 0 43.36px;
               
                h5 {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 28px;
                    display: flex;
                    align-items: center;
                    color: #3B5277;
                    max-height: 56px;
                    overflow: hidden;
                    display: flex;
                    align-items: baseline;
                }
            }
        }

        .slick-slide > div {
            margin: 0;
        }

        .slider-img {
            border-radius: 20px 20px  0 0;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
     }

}

.post-section {
    &-wrapper {
        display: grid;
        grid-template-columns: 245px auto;
        gap: 107.95px;
        @include breakpoint('lg_down') {
            grid-template-columns: auto;
        }
    }

    &-right {
        @include breakpoint('lg_down') {
           padding: 20px 106.72px 24px 48.92px;
         }
         @include breakpoint('md_down') {
          max-width: 309.99px;
          img {
            width: 100%;
          }
          pre, a {
            white-space: pre-line;
            word-break: break-all;
          }
        }
    }

    &-left {
        @include breakpoint('lg_down') {
           order: 2;
           padding: 20px 0 100px 48.92px;
           display: grid;
           grid-template-columns: 245px  auto;
        
        }
        @include breakpoint('md_down') {
          
            grid-template-columns: auto;

            >div:nth-child(2) {
                order: 3;
            }
         
         }
        .group-text {
            gap: 16px;
            margin-bottom: 24px;

            h5 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 0.02em;
                color: #E2857E;
            }

            p {
              color:  rgba(63, 84, 117, 1);
              font-family: 'Outfit';
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: 0.02em;
              word-break: break-all;
            }
        }
    }

    .post-content {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #3F5475;
    }

    .btn {
        margin-top: 40.05px;
        @include breakpoint('lg_down') {
            position: absolute;
            bottom: 182px;
            left: 40%;
        }
        @include breakpoint('md_down') {
            left:50%;
            transform: translateX(-50%);
            width: 236px;
            background: #E2857E;
            border-radius: 40px;
            border-color: transparent;
            color: white;
        }
    }
    .next-section {
        bottom: -2px;
    }
}

.post-wrapper {
    justify-content: flex-start !important;
    padding-top: 77.5px;
    max-height: unset !important;
    padding-bottom: 140px;
    height: 100% !important;
    position: relative;

    .container {
        height: 100%;
    }
}

.banner-post-wrapper {
  .next-section {
    display: none;
  }
}

.suggested-posts-wrapper {
    position: relative;

    .suggested-section{
        @include breakpoint('lg_down') {
            height: 750px;
        }
    }

    .suggested-content {
        @include breakpoint('lg_down') {
            display: flex;

        }

        .header-text {
            @include breakpoint('lg_down') {
                margin: auto;
            }
            @include breakpoint('md_down') {
            font-size: 30px;
            line-height: 78px;
           }
        }
    }

    .suggested-slider{
        @include breakpoint('lg_down') {

            .slider-card {
                width: 324.76px;
            }

            .prev-arrow {
                left: 10px;
                @include breakpoint('md_down') {
                   display: none;
                   }
            }
           .next-arrow
            {
                right: 10px;
                @include breakpoint('md_down') {
                    display: none;
                    }
            }
           .slick-slide > div{
                margin: 0 60px;
            }
        }
    }
}