.banner-personal-legend {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  align-items: flex-end;
  height: 100%;
  color: #35476A;

  @include breakpoint('lg_down') {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height:  100vh;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #7CBDCB 0%, #88CFDE 27.04%);
    top: 105px;
    z-index: 0;
    @include breakpoint('md_down') {
     height: 999.05px;
    }
    @media(max-height: 800px) {
      height: 905px;
   }
  }

  &-image {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 258px;

    @include breakpoint('lg_down') {
      top: unset;
      bottom: 105px;
    }
    

    img {
      width: 100%;
    
    }
  }

  &-text {
    z-index: 10;
    margin-top: 5%;
    @include breakpoint('lg_down') {
      margin-top: 65.25px;
      text-align: center;
    }
    @include breakpoint('md_down') {
      margin-top: 42px;
    }
    .title {
      min-height: 82px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 600;
      line-height: 78px;
      margin-bottom: 30px;
      color: #31446A;

      @include breakpoint('md_down') {
        font-size: 30px;
        line-height: 78px;
        height: 82px;
      }
    }
    .description {
      width: 587px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #31446A;
      margin-bottom: 215.82px;

      @include breakpoint('md_down') {
        font-size: 20px;
        line-height: 26px;
        width: 310.34px;
        height: auto;
        margin-bottom: 0;
      }
  }
  }
  &-wrapper {
    position: relative;

    .banner-wrap {
      @include breakpoint('md_down') {
        height: 1196px;
       }
    }
    .arrow-navigatior-top {
      .triangle {
        border-color: transparent transparent #88CFDE transparent;
      }
    }
  }

  .tree-bg {
 
    @include breakpoint('xlg_down') {
      position: absolute;
      bottom: 105px;
   }
    @include breakpoint('lg_down') {
      display: none;
   }
  }

  .tree-bg-tablet {
    display: none;
    @include breakpoint('lg_down') {
      display: block;
   }
   @include breakpoint('md_down') {
    display: none;
 }
  }

  .tree-bg-mobile {
    display: none;
    @include breakpoint('md_down') {
      display: block;
   }
  }
}