.personal-legend {
  &-divider {
    height: 105px;
    position: absolute;
    left: 0;
    right: 0;
    &__navy {
      background-color: #293856;
    }
    &__blue {
      background-color: #88CFDE;
    }

    &__bottom {
      bottom: 0;
    }
  }

  &-section-2 {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 83.55px;
    @include breakpoint('lg_down') {
      height: 1000px;
      margin-top: 141.59px;
    }

    @include breakpoint('md_down') {
      height: 1380.83px;
      margin-top: 72.1px;
      flex-direction: column-reverse;
    }
    .image {
      position: absolute;
      bottom: 0;
      width: 465px;
      height: 608px;
      left: 90px;
      z-index: 9;
      @include breakpoint('lg_down') {
        bottom: 38px;
        left: 35px;
      }
      @include breakpoint('md_down') {
       position: relative;
       bottom: unset;
       left: unset;
       width: 100%;
       height: auto;
      }

      img {
        position: absolute;
        bottom: 0;
        @include breakpoint('lg_down') {
          position: unset;
          bottom: unset;
        }
        @include breakpoint('md_down') {
          width: 344.23px;
          height: 479.77px;
          margin: 0;
        }
        @include breakpoint('sm_down') {
          margin: auto;
        }
      }
    }

    &-text {
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include breakpoint('lg_down') {
        text-align: center;
        margin: 0 auto;
      }

      @include breakpoint('md_down') {
        width: 300.53px;
        padding-left: 39.43px;
        text-align: left;
        margin-left: 0;
      }
      .title {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 600;
        color: #88CFDE;
        max-width: 970.67px;
        line-height: 63px;
        @include breakpoint('lg_down') {
         margin: auto;
         max-width: 789.68px;
         height: 316.45px;
         margin-bottom: 42.36px;
         line-height: 55px;
        }

        @include breakpoint('md_down') {
          font-size: 30px;
          line-height: 38px;
          margin-bottom: 32px;
        }
      }

      .content {
        margin-left: auto; 

        @include breakpoint('lg_down') {
          position: absolute;
          bottom: 240px;
          right: 54.64px;
          width: 393.34px;
          text-align: left;
        }
        @include breakpoint('md_down') {
          position: relative;
          bottom: unset;
          right:unset;
          width: 300.53px;
        }
      }

      .description {
        color: #88CFDE;
        max-width: 580px;
        margin: 2rem 0 4rem 0;
        line-height: 26px;
        @include breakpoint('md_down') {
          font-size: 22px;
          line-height: 26px;
          width: 300.53px;
          margin: 0 0 48px 0;
        }
      }
    }
    button {
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #88CFDE;
      border-color: #88CFDE;
      @include breakpoint('md_down') {
        margin: auto;
        width: 199px;
        margin-bottom: 50.81px;
        line-height: 26px;
      }
    }
  }
  &-section-3 {
    height: 100%;
    display: flex;
    align-items: center;
    color: #35476A;
    font-family: "Outfit";

    .title {
      max-width: 670px;
      font-weight: 600;
      line-height: 63px;
    }
    .text {
      margin-top: 64px;
      font-size: 22px;
      max-width: 554px;
    }
    .images {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      width: 50%;
      margin-top: 105px;
    }
    .img1 {
      position: absolute;
      height: 392px;
      right: 127px;
      bottom: -105px;
      right: auto;
      z-index: 1;

      & img {
        height: 100%;
      }
    }
    .img2 {
      flex: 1;
      width: 70%;
      margin-bottom: 40%;
    }
  }
  &-section-4 {
    display: flex;
    justify-content: space-between;
    @include breakpoint('md_down') {
      flex-direction: column-reverse;
      height: 1254px;
    }
    .line-bottom {
      position: absolute;
    }

    .bg-tree-img {
      object-fit: cover;
      @include breakpoint('lg_down') {
         display: none;
       }
    }

    .bg-tree-img-tablet {
      display: none;
      @include breakpoint('lg_down') {
         display: block;
       }
       @include breakpoint('md_down') {
        display: none;
      }
    }

    .bg-tree-img-mobile {
      display: none;
      @include breakpoint('md_down') {
         display: block;
         min-width: 732px;
       }
    }

    .img {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 200px;
      @include breakpoint('md_down') {
        top: unset;
        margin-right: -95.61%;
        width: 100%;
        overflow: hidden;
       }
      @include breakpoint('sm_down') {
        top: unset;
        margin-right: -95.61%;
        overflow: hidden;
       }

      & img {
        height: 100%;
        width: 100%;
      }
    }

    .left-side {
      z-index: 1;
      height: 50%;
      width: 50%;
      align-self: flex-end;
      display: flex;
      justify-content: flex-start;
      margin-left: 55px;
      align-items: flex-end;
      position: relative;
      @include breakpoint('lg_down') {
        margin-left: 20px;
      }
      @include breakpoint('md_down') {
        display: none;
      }
      .image {
        width: 259.55px;
        height: 259.55px;
        margin: 120.16px auto 0 auto;
        position: absolute;
        left: 65px;
        @include breakpoint('xlg_down') {
         position: absolute;
         left: 245px;
        }

        img {
          margin-top: 210px;
          margin-left: -20px;
          @include breakpoint('lg_down') {
            margin:0;
            margin: 120.16px auto 0 auto;
          }
        }
      }
    }
    .right-side {
      z-index: 9;
      height: 100%;
      max-width: 580px;
      display: flex;
      flex-direction: column;
      color: #88CFDE;
      margin-right: 80px;
      margin-top: 108.33px;
      position: relative;
      @include breakpoint('lg_down') {
        margin-top: 87.45px;
       }

       @include breakpoint('md_down') {
        margin: 78.43px 0 auto 0;
      }
      .line_image {
        position: absolute;
        left: -183px;
        top: -305px;
        @include breakpoint('lg_down') {
          left: -408px;
         }
         @include breakpoint('md_down') {
          left: 50%;
          top:-255px;
         }
         .line-jr-desktop {
          @include breakpoint('md_down') {
            display: none;
           }
         }
         .line-jr-mobile {
          display: none;
          @include breakpoint('md_down') {
            display: block;
           }
         }

       
        
      }

      .title {
        font-weight: 600;

        @include breakpoint('lg_down') {
          white-space: nowrap;
          margin-left: -135px;
          height: 76.37px;
        }
        @include breakpoint('md_down') {
          font-size: 30px;
          line-height: 20px;
          margin: 0 auto;
          display: flex;
          align-items: center;
        }
      }
      .text {
        margin-top: 48px;
        font-size: 22px;
        line-height: 27px;
        font-weight: 400;
        @include breakpoint('lg_down') {
          margin-top: 92.43px;
          width: 403.86px;
         }

         @include breakpoint('md_down') {
          font-size: 20px;
          line-height: 25px;
          margin-top: 31.8px;
          padding-left: 32.81px;
          width: 303.79px;
         }

        .font600 {
          font-weight: 600;
          font-family: Outfit;
        }
      }
    }
  }
  &-section-5 {
    height: 100%;
    display: flex;
    color: #3B5277;
    align-items: flex-start;
    margin-top: 99.17px;
    @include breakpoint('lg_down') {
      margin-top: 77.15px;
      height: 1000px;
    }
    @include breakpoint('md_down') {
      margin-top: 70.64px;
      height: 1391px;
      flex-direction: column;
    }
    .text {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 54.55px;

      @include breakpoint('lg_down') {
        margin-top: 0;
        z-index: 10;
      }
      @include breakpoint('md_down') {
        padding-left: 32.12px;
        max-height: 225px;
      }

      .title {
        font-weight: 600;
        margin-bottom: 64px;
        @include breakpoint('lg_down') {
          line-height: 55px;
          margin-bottom: 268.77px;
          max-width: 782px;
          position: absolute;
          left: 53.39px;
        }
        @include breakpoint('md_down') {
          font-size: 30px;
          line-height: 38px;
          max-width: 310.76px;
          position: relative;
          left: unset;
          right: unset;
        }
      }
      .paragraph {
        max-width: 590px;
        font-size: 22px;
        margin-left: auto;
        line-height: 26px;
        @include breakpoint('lg_down') {
          margin-top: auto;
          margin-left: 57.88px;
          margin-bottom: 229.71px;
          width: 403.19px;
        }
        @include breakpoint('md_down') {
          position: absolute;
          bottom: 240px;
          margin: 0;
          width: 315.59px;
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
    .images {
      width: 650px;
      position: relative;
      display: flex;
      align-items: flex-end;
      @include breakpoint('lg_down') {
        height: 100%;
      }

      @include breakpoint('md_down') {
        height: 832.15px;
      }

      .profile {
        position: absolute;
        right: 0;
        width: 100%;
        bottom: 0;
        height: auto;
        z-index: 9;
        top: 0;
        @include breakpoint('lg_down') {
          bottom: 20px;
          right: 0;
        }

        .avatar-rosita {
          @include breakpoint('lg_down') {
           display: none;
          }
        }

        .wrapper-img-stars {
          display: none;
          @include breakpoint('lg_down') {
            background-image: url("../../img/personal_legend/stars-bg-girl.svg");
            background-repeat: no-repeat;
            height: 816px;
            display: block;
            position: absolute;
            width: 100%;
            bottom: 0;
            right: 0;
          }
     
          @include breakpoint('md_down') {
            left: 0;
            background-image: url("../../img/personal_legend/stars-bg-min-tablet.svg");
            background-position-x: center;
            background-size: cover;
            position: relative;
            left: unset;
            display: flex;
            justify-content: center;
            width: 100vw;
            right: unset;
            img {
              max-height: 547px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }

        .avatar-rosita-tablet {
          display: none;
          @include breakpoint('lg_down') {
           display: block;
           position: absolute;
           left: 183.66px;
           top: 142.98px;
          }
          @include breakpoint('md_down') {
            left: 26.5%;
            transform: translateX(-45%);
          }
        }
      }
      .role {
        z-index: 1;
        position: absolute;
        bottom: 0px;
        width: 445px;
        height: 550px;
        left: 196px;
      }
    }
  }
  &-section-6 {
    height: 100%;
    display: flex;
    gap: 120px;
    
    .images {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .compass {
        width: 313px;
        height: 550px;
        z-index: 2;
      }
      .token {
        position: absolute;
        width: 476px;
        height: 620px;
        bottom: -58px;
        right: 180px;
        z-index: 1;
      }
    }
    .content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #3F5475;

      .title {
        font-weight: 600;
        margin-bottom: 64px;
      }
      .text {
        max-width: 465px;
        font-size: 22px;
      }
    }
  }
  &-achievements {
    background-color: #293856;
    
    .number-circle {
      position: absolute;
      width: 3.25%;
      height: 0.0325 * 100vw;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      font-weight: 600;
      color: #35476A;

      &-1 {
        left: 17.6%;
        bottom: 5%;

        @media(min-height: 900px) {
          bottom: 2%;
        }
      }

      &-2 {
        left: 55.98%;
        bottom: 5%;

        @media(min-height: 900px) {
          bottom: 2%;
        }
      }
    }
    .bg {
      z-index: 9;
      position: absolute;
      &-1 {
        top: -100px;
        right: 0;
        left: 0;
        bottom: 0;

        & img {
          width: 100%;
          height: 100%;
        }
      }
      &-2 {
        bottom: 0;
      }
    }
    .section-1 {
      color: #88CFDE;
      display: flex;
      flex-direction: column;
      position: relative;
      height: 800px;

      &--last-steps {
        height: 800px;
        padding-bottom: 69px;

        .dashed-line {
          position: absolute;
          height: 2px;
          width: calc(50% + 150px);
          left: 0;
          bottom: 67px;
        }
      }
      .title {
        opacity: 0;
        font-weight: 600;
        margin-bottom: 32px;
      }
      .text {
        opacity: 0;
        font-size: 22px;
        max-width: 667px;
      }

      .cards-container {
        margin-top: 45px;
        flex: 1;
        display: flex;
        align-items: flex-end;
        gap: 24px;
        position: relative;
        z-index: 999;
    

        &-bg {
          position: absolute;
          height: 360px;
          background-color: #1E2A41;
          z-index: 99;
          bottom: 122px;
          
          &--left {
            left: 0;
            right: 3%;
            border-radius: 0 50px 50px 0;
          }
          &--right {
            right: 0;
            left: 3%;
            border-radius: 50px 0 0 50px;
            bottom: 135px;
          }

          &--last {
            bottom: 196px;
          }

          &--2 {
            bottom: 170px;
          }
        }

        .card-item {
          flex: 1;
          display: flex;
          height: 100%;
          z-index: 10;
          align-items: center;
          max-height: 629px;
        }

        .card-left {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          justify-content: flex-end;
        }

        .card-right {
          flex: 1;
          margin: 0 40px;
          margin-top: 46px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          max-height: 300px;


          & li {
            list-style: disc;
            margin-left: 24px;
            font-weight: 400;
            font-size: 16px;
          }

          .font-600 {
            font-weight: 600;
          }
        }

        .card-name {
          font-size: 22px;
          margin-bottom: 20px;
          font-weight: 600;
        }

        .card-text {
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .card-number {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          margin-bottom: -26px;
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          font-weight: 600;
          color: #35476A;
        }

        .card-image {
          width: 300px;
          height: calc(100% - 100px);
          object-fit: fill;
          max-height: 520px;
        }
      }
    }
  }
}

.achievements-wrapper {
  max-height: unset !important;
  height: 100% !important;
  position: relative;
  @include breakpoint('lg_down') {
    display: none;
  }
}

.section2-wrapper, .section3-wrapper, .section4-wrapper {
  position: relative;
  @include breakpoint('lg_down') {
    height: 1105px;
  }

}

.section2-wrapper {
  .container {
    min-height: 905px;
    @include breakpoint('lg_down') {
      min-height:  unset;
    }
  }
  @include breakpoint('md_down') {
    height: 1380.83px;
  }
}
.section3-wrapper {
  min-height: 905px;
  @include breakpoint('md_down') {
    height: 1580px;
    overflow-x: hidden;
  }
}

.section4-wrapper {
  @include breakpoint('md_down') {
    height: 1254px;
  }
}

.separator-wrapper  {
  position: relative;
  width: 100%;
    display: block;
    margin-top: 104px;
}
