.company-value {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    height: 100%;
    align-content: center;
    
    &-image {
        position: relative;
        @include display(flex, flex-end);
        height: 100%;

        h2 {
            font-size: 40px;
            line-height: 48px;
            max-width: 550px;
            position: absolute;
            top: 30px;
            z-index: 3;
        }
        
        .rocket-man {
            z-index: 2;
            max-width: 540px;
        }
    }

    &-content {
        &-inner { 
            &:not(:last-child) {
                margin-bottom: 10px;
            } 

            h5 {
                max-width: 500px;
                font-family: 'Proxima Nova';
                line-height: 28px;
            }
        }
    }
}