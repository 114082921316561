.connect-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 200px;
    height: 100%;

    img {
        &.three-leaf {
            @include position(absolute, 30px, 0, 0, 0);
            margin: 0 auto;
            max-width: 600px;
        }
    }
    
    &-inputs {
        @include display(flex, initial, center, column);
        height: 100%;

        h2 {
            font-size: 40px;
            line-height: 48px;
        }
    }

    &-content {
        @include display(flex, initial, center, column);
        &-inner { 
            &:not(:last-child) {
                margin-bottom: 50px;
            }

            h3 {
                font-size: 30px;
            }
            h4 {
                font-family: 'Proxima Nova';
                font-size: 22px;
                line-height: 28px;
            }
            
            a {
                display: inline-block;
                position: relative;
                z-index: 9;
            }
        }
    }

    .css-1okebmr-indicatorSeparator {
        display: none;
    }
    .css-tlfecz-indicatorContainer {
        padding-right: 27px;
        color: var(--color-gray-dark);
    }
    .css-319lph-ValueContainer {
        padding-left: 16px;
    }

    &-button {
        z-index: 2;
        position: relative;
    }
}