.team {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 70px;
    grid-row-gap: 40px;
    padding-bottom: 150px;

    &-box {
        background-color: var(--color-white);
        padding: 30px 30px 20px 30px;
        border-radius: 0px 40px 0 0px;
        @include display(flex, center, center, column);

        h5 {
            text-align: center;
        }
    
        &-social-media {
            @include display(flex, center, center, initial);
    
            a {
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }

        &-opening {
            border: 1px solid var(--color-blue-light);
            border-radius: 0 40px 0 0;
            background-color: transparent;
            justify-content: space-between;
            padding: 50px;

            h5 {
                font-size: 18px;
                font-family: 'Proxima Nova';
                text-align: center;
            }
        }
    }
}