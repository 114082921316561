.join {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    &-content {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        align-items: center;
        z-index: 99;

            .header-text {
                color: var(--color-secondary); 
                min-height: 84.71px;;
                text-align: center;
                margin-bottom: 56.62px;
                font-style: normal;
                font-weight: 600;
                font-size: 50px;
                line-height: 63px;
                text-align: center;
                letter-spacing: 0.02em;
                @include breakpoint('lg_down') {
                    margin-bottom: 63.43px;
                }

                @include breakpoint('md_down') {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 38px;
                }
            }

            .desc {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 38px;
                text-align: center;
                letter-spacing: 0.02em;
                height: 84.71px;
                color: var(--color-white);
                @include breakpoint('lg_down') {
                    display: none;
                }
            }
    }

    &-section {
        height: 708.81px;
        @include breakpoint('lg_down') {
            height: 660.81px;
        }
        @include breakpoint('md_down') {
            height: 1000.93px;
        }
        .mini-title {
            margin-top: 39.17px;
        }
    }

    .btn {
        border-color:#9CCBA4;
        border-radius: 100px;
        color: var(--color-white);
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        min-height: 44px;
      
    }

    button {
        @include breakpoint('md_down') {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
        }
    }

    &-images {
        position: relative;
    
        .road {
            position: absolute;
            z-index: 9;
            width: 1147.52px;
            left: 49px;
            top: -465px;
            transform: rotate(-6.74deg);
            @include breakpoint('xlg_down') {
                left: 0;
              }
            @include breakpoint('lg_down') {
                display: none;
              }
        }

        .road-tablet {
            display: none;
            @include breakpoint('lg_down') {
                position: absolute;
                z-index: 9;
                left: 65.41px;
                top: -540px;
                transform: rotate(-6.74deg);
                display: block;
              }
              @include breakpoint('md_down') {
                display: none;
              }
            
        }

        .road-mobile {
            display: none;
              @include breakpoint('md_down') {
                position: absolute;
                z-index: 9;
                transform: rotate(-6.74deg);
                display: block;
                left: 179.99px;
                top: -200px;
            }
            @include breakpoint('sm_down') {
                left: 27.99px;
            }
        }
    }
  
    .boxes-wrapper {
        display: grid;
        grid-template-columns: 826.2px 402.76px;
        grid-auto-rows: minmax(100px,  285.1px);
        gap: 64.94px;
        margin: auto;
        z-index: 99;
        min-height: 296.8px;
        @include breakpoint('xlg_down') {
            grid-template-columns: minmax(600px, 700px) 275.36px;
            grid-auto-rows: minmax(100px,  249.64px);
            gap: 47.81px;
        }
        @include breakpoint('lg_down') {
            grid-template-columns: 575.04px 275.36px;
            grid-auto-rows: minmax(100px,  249.64px);
            gap: 47.81px;
        }
        @include breakpoint('md_down') {
            grid-template-columns: 320px;
            grid-auto-rows: minmax(100px,  310.7px);
            row-gap: 28.58px;
        }

        .box {
            background-color: #35476A;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;

            h6 {
                color: var(--color-white);
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 30px; 
                text-align: center;
            }

            &--discord {
                .discord-icon {
                    margin-bottom: 27.77px;
                }
                button {
                   margin-top: 27.83px;
                   line-height: 26px;
                }
            }

            &--twitter {
                .twitter-icon {
                    margin-bottom: 27.56px;
                }

                button {
                    margin-top: 27.83px;
                    line-height: 26px;
                }
            }

        }
    }

    &-wrapper {
        margin-bottom: -170px;
        background: linear-gradient(180deg, #1E2A41 0%, #293856 52.14%);
        @include breakpoint('lg_down') {
            background: linear-gradient(180deg, #1E2A41 0%, #293856 43.14%);
        }
        @include breakpoint('md_down') {
            background: linear-gradient(180deg, #1E2A41 0%, #26385C 25.44%);
        }
    }

    &-section {
        padding-top: 268px;
        @include breakpoint('md_down') {
            padding-top: 63px;
            
        }
    }
}