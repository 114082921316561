.achievements-wrapper-tablet {
   display: none;
    @include breakpoint('lg_down') {
       .personal-legend-achievements-tablet-section {
            position: relative;
        }
        .font-600 {
            font-weight: 600;
        }

       display: block;

       .card {

        &-left{
            z-index: 9;
        }
       &-item {
        display: flex;
        height: 380px;
        background: #1E2A41;
        border-radius: 51.5606px 0 0 51.5606px;
        width: calc(100% - 53px);
        margin-left: auto;
        align-items: center;
        justify-content: center;
        gap: 243.87px;

        ul {
            margin-left: 16px;
            max-width: 239px;
            li {
                list-style: disc;
            }
        }

        .meaining-text {
            width: 241.38px;
            height: 55.4px;
            margin-bottom: 17.74px;
        }
       }
      &-image {
            width: 261px;
            height: 458.3px
        }

        &-name {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
            letter-spacing: 0.02em;
            height: 29.07px;
            margin-bottom: 20.01px;
        }

        &-text {
            font-family: 'Outfit';
            font-style: normal;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: #88CFDE;
        }

    }
       .rtl {
        flex-direction: row-reverse;

        .card-item {
            border-radius: 0 51.5606px 51.5606px 0;
            width: calc(100% - 53px);
        }
       }

       .ach-section2 {
            margin-top: 75.57px;
       }

       .ach-section3{
        margin-top: 78.71px;
       }

       .ach-section4 {
        margin-top: 79.42px;
       }
       .ach-section5 {
        margin-top: 78.71px;
       }
       .ach-section6 {
        margin-top: 76.56px;
       }
   

    .bg-dotted {
        position: absolute;
        left: 50%;
        top: -200px;
    }
}

    @include breakpoint('md_down') {
        display: block;
        height: 5416.09px;
    }
  
    .bg-dotted {
        @include breakpoint('md_down') {
        display: none;
        }
    }

    .bg-dotted-mobile {
        display: none;
        @include breakpoint('md_down') {
        position: absolute;
        left: 30.86px;
        top: -149.26px;
        display: block;
    }
    }

    @include breakpoint('md_down') { 
        .personal-legend-achievements-tablet-section {
            .bg-navy {
                height: 881.88px;
            }
        }
        .card-item {
            border-radius: 0;
            width: 100%;
            height: 500px;
            flex-direction: column;
            gap:0;
            justify-content: unset;
        }
        .card-name {
            display: none;
        }

        .card-image {
            width: 274.19px;
            height: 479.83px;
            margin-top: -282px;
            margin-bottom: 32.78px;
        }

        .card-left, .card-right {
            padding-left: 72.7px;
        }

        .ach-section1 {
            padding-top: 386.11px;
        }
        .ach-section2, .ach-section3, .ach-section4, .ach-section5 {
            margin: 0;
        }
        .ach-section6{
            margin-top: 42px;
        }
    }
}