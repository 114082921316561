.roadmap {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;

    .left-mountain-text {
        display: flex;
        flex-direction: column;
        gap: 41.17px;
        position: absolute;
        left: 485.17px;
        top: 361px;

        @media (max-width: 1370px) {
            left: 387.17px;
            top: 300px;
            gap: 27.17px;
        }

        @include breakpoint('lg_down') {
            gap: 41.17px;
            left: 515.34px;
            top: 382px;
        }

        @include breakpoint('sm_down') {
            left: 660px;
            top: 330px;
            text-align: left;
            gap: 70px;
        }

        span {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
            text-align: right;
            letter-spacing: 0.1em;
            color: #F47500;
            height: 37.76px;
            text-align: right;

            @include breakpoint('sm_down') {
                text-align: left;
            }
        }

        @include breakpoint('sm_down') {
            span:nth-child(5) {
                margin-top: 30px;
            }

            span:nth-child(6) {
                margin-top: 30px;
            }
        }
    }

    .right-mountain-text {
        display: flex;
        flex-direction: column;
        gap: 25.9px;
        position: absolute;
        left: 727.69px;
        top: 347px;

        @media (max-width: 1370px) {
            gap: 12.9px;
            left: 602.69px;
            top: 293px;
        }

        @include breakpoint('lg_down') {
            gap: 25.9px;
            left: 759.38px;
            top: 371px;
        }

        @include breakpoint('sm_down') {
            left: 660px;
            top: 352px;
            gap: 55px;
        }

        span {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            height: 53.4px;
            width: 317.55px;

            @include breakpoint('sm_down') {
                width: 268.47px;
                height: unset;
                min-height: 53.4px;
            }
        }

        @include breakpoint('sm_down') {
            span:nth-child(4) {
                min-height: 82px;
            }

            span:nth-child(5) {
                min-height: 82px;
            }
        }
    }

    &-img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-left: -105px;
        z-index: 9;
        bottom: 105px;

        @include breakpoint('lg_down') {
            left: -392px;
            transform: unset;
            margin: 0;
            top: 43.43px;
            bottom: unset;
        }

        @include breakpoint('xs_down') {
            left: -578px;
        }
    }

    .image-wrapper-mountain {
        position: relative;

        .img-mountain {
            min-width: 2058.12px;

            @media (max-width: 1370px) {
                min-width: 1685px;
            }

            @include breakpoint('lg_down') {
                display: none;
            }
        }

        .img-mountain-tablet {
            display: none;

            @include breakpoint('lg_down') {
                display: block;
                min-width: 2058.12px;
            }

            @include breakpoint('sm_down') {
                display: none;
            }
        }

        .img-mountain-mobile {
            display: none;

            @include breakpoint('sm_down') {
                display: block;
                min-width: 2058.12px;
            }
        }

        .line-dots {
            position: absolute;
            top: 190px;
            left: 675px;

            @media (max-width: 1370px) {
                top: 160px;
                left: 555px;
                height: 620px;
            }

            @include breakpoint('lg_down') {
                height: unset;
                top: 212px;
                left: 705px;
            }

            @include breakpoint('sm_down') {
                display: none;
            }
        }

        .line-mobile-dots {
            display: none;

            @include breakpoint('sm_down') {
                display: block;
                position: absolute;
                left: 610px;
                top: 164px;
            }
        }
    }

    .giraffe-coat {
        position: absolute;
        z-index: 999;
        left: 1390px;
        bottom: -56px;

        @media (max-width: 1370px) {
            left: 1050px;

        }

        @include breakpoint('lg_down') {
            bottom: -70px;
        }

        @include breakpoint('md_down') {
            left: 909px;
        }

        @include breakpoint('sm_down') {
            bottom: -925px;
            left: 664px;
        }

    }

    &-content {
        margin: 150px 24px 0 0;
        z-index: 99;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 450px;
        width: 480px;

        @media (max-width: 1370px) {
            margin-left: 300px;
        }

        @include breakpoint('lg_down') {
            bottom: 180px;
            margin: 0;
            left: 362px;
        }

        @include breakpoint('sm_down') {
            left: 397px;
            width: 261px;
            bottom: 580px;
        }

        @include breakpoint('xs_down') {
            left: 214px;
        }

        .header-text {
            color: var(--color-blue-light);
            text-align: left;
            margin-bottom: 23.85px;
        }

        .description {
            color: var(--color-blue-light);
            max-width: 486.37px;
            text-align: left;
        }
    }

    &-section {
        min-height: 995px;

        @include breakpoint('lg_down') {
            height: 1395px;
        }

        @include breakpoint('sm_down') {
            height: 1879px;
        }

        .mini-title {
            margin-top: 45.7px;
        }
    }


    &-wrapper {
        position: relative;
        overflow: hidden;

        .next-section {
            background: #354466;
        }
    }
}