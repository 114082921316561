/*// -----------------------------------------------------------------------------
// This file contains all styles related to the Header of the site/application.
// -----------------------------------------------------------------------------
*/

/* =Header Styles
-------------------------------------------------------------- */
.header {
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  height: 105px;

  @include breakpoint('xs_down') {
    position: relative;
    top: initial;
    display: flex;
    justify-content: center;
  }

  &-logo {
    img {
      margin-top: -20px;
      @include breakpoint('xs_down') {
        margin: 0;
      }
    }
  }

  .nav-menu {
    align-self: flex-end;
    margin-bottom: 34.16px;

    @include breakpoint('lg_down') {
      background: white;
			flex-direction: column;
			width: 100%;
			position: fixed;
			left: 0;
			height: 100%;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: translateX(-100%);
			transition: .3s all ease-in-out;
			transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    }


    ul {
      display: flex;
      align-items: center;
      @include breakpoint('lg_down') {
        flex-direction: column;
      }

      li {
        display: inline-flex;
        @include breakpoint('lg_down') {
          margin-bottom: 20px;
        }
        &:not(:last-child) {
          margin-right: 60px;
          @include breakpoint('lg_down') {
            margin-right: 0;
          }
        }

        a {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 26px;
          letter-spacing: 0.02em;
          color: var(--color-primary);
          @include breakpoint('lg_down') {
            font-size: 18px;
          }
          &:hover {
            color: var(--color-white);
            @include breakpoint('lg_down') {
              color: var(--color-black);
            }
          }
        }
      }
      .active {
        a {
          color: var(--color-white);
          @include breakpoint('lg_down') {
            color: var(--color-black);
          }
        }
      }
    }

    @include breakpoint('lg_down') {
      &.active {
				transform: translateX(0);
          ul {
              li {
                  opacity: 0;
                  animation: fadeInUp 0.6s 1;
                  animation-fill-mode: forwards;

                  &:nth-child(4n+1) { 
                      animation-delay: 0.2s;
                  }

                  &:nth-child(4n+2) { 
                      animation-delay: 0.3s;
                  }

                  &:nth-child(4n+3) { 
                      animation-delay: 0.4s;
                  }

                  &:nth-child(4n+4) {
                      animation-delay: 0.5s;
                  }
              }
          }
			}

    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    @include breakpoint('xlg_down') {
      padding-right: 40px;
      }
    @include breakpoint('sm_down') {
      padding-right: 40px;
    }
    @include breakpoint('xs_down') {
      display: none;
    } 
    .btn {
      padding: 0 15px;
      min-width: 100px;
      font-size: 14px;
    }
  }

  &.header-get-started {
    ul {
      li {
        a {
          color: var(--color-green);
        }
      }
    }

    .header-buttons { 
      .btn {
        &--outline {
          @include button(transparent, green);
          border: 1px solid var(--color-green);
          font-family: 'Proxima Nova';
        }

        &--secondary {
          @include button(var(--color-dark-blue), white);
        }
      }
    }
  }

  @include breakpoint('lg_up') {

  &-green {
    .nav-menu {
      ul {
        li {
  
          a {
            color: var(--color-green);
  
            &:hover {
              color: var(--color-white);
            }
          }
        }
        .active {
          a {
            color: var(--color-white);
          }
        }
      }
    }
    .btn {
      border-color: var(--color-green);
      background-color: var(--color-green);
    }
    
    .btn--outline {
      color: var(--color-green);
      background-color: transparent;
    }
  }
}

  .btn--secondary {
    width:  168px;
    height: 56px;
    background: #3B5277;
    border-radius: 40px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;  
    line-height: 24px;
  }
}


/* Hamburger Button Menu*/
.main-menu-indicator {
	display: none;
	padding: 0px;
  cursor: pointer;
  z-index: 9;
  height: 50px;
  width: 18px;
  background: transparent;
  float: right;
  position: relative;
  margin: 0px 0px 0px 22.84px;
  outline: none;
  border: none;
  transform: rotateZ(180deg);

	@include on-event ($self: false) {
	  box-shadow: none;
	}

	> span {
		transition: all 0.1s ease 0s;
    position: relative;
    width: 9px;
    height: 2px;
    margin: auto;
    background: rgb(28, 41, 66);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

		&:before,
		&:after {
      transition: all 0.2s ease 0s;
      position: absolute;
      content: "";
      width: 18px;
      height: 2px;
      background: rgb(28, 41, 66);
      left: 0px;
		}

		&:after {
			top: -7px;
		}

		&:before {
			bottom: -7px;
		}
	}

	&.active > span {
		background: transparent;

		&:after {
			top: 0;
			transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-webkit-transform-origin: center center;
			-ms-transform-origin: center center;
			transform-origin: center center;
			background: var(--color-primary);
		}

		&:before {
			bottom: 0;
			transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-webkit-transform-origin: center center;
			-ms-transform-origin: center center;
			transform-origin: center center;
			background: var(--color-primary);
		}
	}
}

.header-get-started {
  .main-menu-indicator { 
    span {
      background-color: var(--color-white);

      &::before, 
      &::after {
        background-color: var(--color-white);
      }
    }

    &.active > span { 
        background: transparent;
        
        &::before, 
        &::after {
          background: var(--color-primary);
        }
    }
  }
}

.header-logo {
  padding-top: 11.38px;
  @include breakpoint('xlg_down') {
    padding-left: 51px;
    }
  @include breakpoint('sm_down') {
    padding-left: 40px;
  }
  @include breakpoint('xs_down') {
    padding: 0;
    width: 201px;
    height: 42px;
    margin: 0;
  }
}

.main-menu-indicator {
  @include breakpoint('lg_down') {
    display: block;
  }
}