.banner-what-we-create {
  height: 100%;
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100vh;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, #1E2A41 0%, #293856 25.49%);
      top: 105px;
   
      @media(max-height: 800px) {
        height: 905px;
     }
     
      @include breakpoint('lg_down') {
        height: 800px;
      }

      @include breakpoint('md_down') {
        height: 809.04px;
      }
    }
    &-text {
      padding-top: 212.25px;
      z-index: 10;
      position: relative;
      @include breakpoint('xlg_down') {
        padding-left: 48.35px;
      }
      @include breakpoint('lg_down') {
        padding-top: 62.05px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-width: 740px;
        margin: auto;
      }
      @include breakpoint('md_down') {
        padding: 66.29px 0 0 0; 
       }
    }
    .bg-lines {
      position: absolute;
      bottom: 105px;
      left: 0;
      right: 0;
      z-index: 8;
      width: 100%;
      height: 394.72px;
      object-fit: cover;
      @include breakpoint('lg_down') {
        display: none;
      }
    }

    .bg-lines-tablet {
      display: none;
      @include breakpoint('lg_down') {
        position: absolute;
        bottom: 105px;
        left: 48.76px;
        right: 0;
        z-index: 8;
        width: calc(100% - 48.76px);
        display: block;
      }
      @include breakpoint('md_down') {
        left: 0;
        width: 100%;
        height: 154px;
        object-fit: cover;
      }
    }
    .people-sitting {
      position: absolute;
      right: 0; 
      z-index: 9;
      bottom: 38px;
      width: 869.79px;
      height: 660.59px;
      left: 50%;
      transform: translateX(-34%);
      @include breakpoint('lg_down') {
        bottom: 48px;
        left: unset;
        transform: unset;
      }
      @include breakpoint('md_down') {
        bottom: 84px;
        width: 318.1px;
        height: 253.99px;
        left: 10px;
      }
    }

    .header-text {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 600;
      font-size: 70px;
      line-height: 88px;
      color: #82C394;
      height: 88px;
      margin-bottom: 28px;
      @include breakpoint('md_down') {
        font-size: 30px;
        line-height: 38px;
        text-align: center;
        height: unset;
       }
    }

    .description {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #8DCF9F;
      width: 740px;
      @include breakpoint('md_down') {
        font-size: 20px;
        line-height: 26px;
        width: 304.69px;
        text-align: center;
       }
    }
}
.technology-wrapper, .relationships-wrapper, .insights-wrapper, .mission-section-wrapper {
  .fp-tableCell {
    height: 100%!important;
  }
}
.technology-section {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    height: 905px;
    @include breakpoint('lg_down') {
     height: 905px;
    }
    @include breakpoint('md_down') {
      grid-template-columns: auto;
      align-items: baseline;
    }
    .line1 {
        position: absolute;
        left: calc(50% - 445px);
        top: -55px;
        z-index: 9;
        @include breakpoint('lg_down') {
          left: calc(50% - 405px);
       }
       @include breakpoint('md_down') {
         left: 8.32px;
      }
       .tech-line1 {
        @include breakpoint('lg_down') {
          display: none;
       }
       }
       .tech-line1-tablet {
         display: none;
        @include breakpoint('lg_down') {
            display: block;
        }
        @include breakpoint('md_down') {
          display: none;
        }
       }

      .tech-line-mobile {
        display: none;
       @include breakpoint('md_down') {
         display: block;
       }
      }
    }
  &-left {
    padding-top: 60px;
    max-width: 556px;

    @include breakpoint('lg_down') {
      padding-top: 0;
      padding-left: 48.69px;
      margin-top: -40px;
    }

    @include breakpoint('md_down') {
      padding-left: 65.95px;
      width: 263.57px;
      padding-top: 162.21px;
     }
     @include breakpoint('sm_down') {
      margin: 0 auto;
     }
    .header-text{
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.02em;
      margin-bottom: 42px;
      color: #31446A;
      
      @include breakpoint('lg_down') {
        margin-bottom: 36.86px;
     }
     @include breakpoint('md_down') {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 26.6px;
     }
    }

    .description {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #31446A;
      @include breakpoint('lg_down') {
        max-width: 426.78px;
     }

     @include breakpoint('md_down') {
      height: 249.78px;
      font-size: 18px;
      line-height: 23px;
      width: 263.57px;
     }
    }

    ul {
        margin: 16px 0 0 16px;
    }

    li {
        list-style: disc;
    }
  }

  &-right {
    margin-top: 115px;
    z-index: 9;
    @include breakpoint('lg_down') {
      margin:  230px auto 0 auto;
    }
    @include breakpoint('md_down') {
     margin: 0 0 75px 0;
     padding-left: 65.95px;
    }
    @include breakpoint('sm_down') {
      margin: 0 auto 75px auto;
     }
    img {
        margin: auto;
        @include breakpoint('lg_down') {
          max-width: 446.61px;
          max-height: 551.93px;
       }
      @include breakpoint('md_down') {
        width: 281.42px;
        height: 350.15px;
        margin: 0;
     }
     @include breakpoint('sm_down') {
      margin:auto;
     }
    }
  }
}

.relationships-section {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    height: 905px;
    @include breakpoint('lg_down') {
      height: 905px;
   }

   @include breakpoint('md_down') {
    grid-template-columns: auto;
    align-items: baseline;
    height: 977.85px;
  }

    .line2 {
        position: absolute;
        left: calc(50% - 77px);
        top: 125px;
        z-index: 9;
        @include breakpoint('lg_down') {
          left: calc(50% - 38px);
        }
        .rel-line1 {
          @include breakpoint('lg_down') {
           display: none;
         }
        }
        .rel-line1-tablet {
          display: none;
          @include breakpoint('lg_down') {
           display: block;
         }
         @include breakpoint('md_down') {
          display: none;
        }
        }
    }
  &-right {
    max-width: 500px;
    margin: 0 auto;
    @include breakpoint('md_down') {
      padding-left: 66.67px;
      width: 277.89px;
      padding-top:  172.07px;
      margin:0;
     }
     @include breakpoint('sm_down') {
      margin: 0 auto;
     }
    .header-text{
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #31446A;
      margin-bottom: 42px;
      @include breakpoint('lg_down') {
        margin-bottom: 36.86px;
        max-width: 424.61px;
     }

     @include breakpoint('md_down') {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 30px;
     }
      
    }

    .description {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #31446A;
      width: 477.68px;
      @include breakpoint('lg_down') {
        max-width: 405.16px;
     }

     @include breakpoint('md_down') {
      width: 277.89px;
      height: 268.64px;
      font-size: 20px;
      line-height: 25px;
     }
    }

    ul {
        margin: 16px 0 0 16px;
    }

    li {
        list-style: disc;
    }
  }

  &-left {
    margin-top: 115px;
    margin-right: auto;
    z-index: 9;
    @include breakpoint('lg_down') {
      margin-top: 250px;
      padding-left: 50.14px;
    }

    @include breakpoint('md_down') {
      order: 2;
      margin: 0;
   }

    img {
        margin: auto;
        @include breakpoint('lg_down') {
          max-width: 428.81px;
          max-height: 555.75px;
       }
       @include breakpoint('md_down') {
        width: 288.01px;
        height: 369.16px;
        margin-bottom: 28.14px;
        margin-left: 0;
        margin-right: 0;
      }
      @include breakpoint('sm_down') {
        margin: auto;
       }
    }
  }
}

.insights-section {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    height: 905px;
    @include breakpoint('lg_down') {
      height: 905px;
   }
   @include breakpoint('md_down') {
    grid-template-columns: auto;
    align-items: baseline;
    height: 1037.48px;
  }

    .line3 {
        position: absolute;
        left: calc(50% - 378px);
        top: 120px;
        z-index: 9;
        @include breakpoint('lg_down') {
          left: calc(50% - 342px);
       }

       .line3-insight {
          @include breakpoint('lg_down') {
          display: none;
        }
       }

      .line3-insight-tablet {
        display: none;
        @include breakpoint('lg_down') {
          display: block;
        }
        @include breakpoint('md_down') {
          display: none;
        }
      }
    }
  &-left {
    padding-top: 60px;
    max-width: 600px;
    @include breakpoint('lg_down') {
      padding-top: 90px;
      padding-left: 48.69px;
    }
    @include breakpoint('md_down') {
      grid-template-columns: auto;
      padding-top: 196.51px;
      padding-left: 65.94px;
      width: 280.85px;
    }

    .header-text{
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #35476A;
      margin-bottom: 42px;
      @include breakpoint('lg_down') {
        margin-bottom: 36.86px;
        max-width: 377.9px;
     }
     @include breakpoint('md_down') {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 16px;
     }
    }

    .description {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #293856;
      @include breakpoint('lg_down') {
        max-width:428.81px;
      }
      @include breakpoint('md_down') {
        font-size: 20px;
        line-height: 25px;
      }
    }

    ul {
        margin: 16px 0 0 16px;
    }

    li {
        list-style: disc;
    }
  }

  &-right {
    margin-top: 115px;
    z-index: 9;
    margin-top: auto;
    margin-bottom: 23.89px;
    @include breakpoint('lg_down') {
      margin-right: auto;
      margin-top: 360px;
    }
    @include breakpoint('md_down') {
       margin: 0;
    }
    img {
        margin: auto;
        @include breakpoint('lg_down') {
          max-width: 431.38px;
       }
       @include breakpoint('md_down') {
        width: 277.89px;
        height: 295.29px;
        margin: 20px 0 0 60px;
      }
      @include breakpoint('sm_down') {
      margin: auto;
      }
    }
  }
}

.mission {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  align-items: center;
  height: 100%;
  
  @include breakpoint('md_down') {
    grid-template-columns: auto;
    align-items: baseline;
    padding: 83.74px 0 0 34.55px;
 }
  &-image {
      position: relative;
      @include display(flex, flex-end);
      height: 100%;
      margin-bottom: 61px;
      @include breakpoint('lg_down') {
        margin-bottom: 68px;
     }
     @include breakpoint('md_down') {
      order: 2;
   }
      .tree {
          z-index: 3;
          margin-bottom: 258px;
      }

      .people-hugging {
          position: absolute;
          z-index: 3;

          @include breakpoint('md_down') {
            bottom: 59.68px;
            max-width: 312.8px;
            max-height: 359.82px;
         }
      }
  }

  &-content {

      &-description {  
        @include breakpoint('lg_down') {
          margin-top: -130px;
       }     
       @include breakpoint('md_down') {
        margin-top: 0;
       }     
          .header-text {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 600;
            font-size: 50px;
            line-height: 63px;
            color: #E2857E;
            margin-bottom: 30px;
            min-height: 63px;
            @include breakpoint('md_down') {
              font-size: 30px;
              line-height: 38px;
              min-height: unset;
             }
          }
          .description {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 26px;
            letter-spacing: 0.02em;
            color: #E2857E;
            width: 581px;
            @include breakpoint('lg_down') {
              max-width: 419.95px;
              width: 100%;
           }
           @include breakpoint('md_down') {
            font-weight: 400;
            font-size: 20px;
            line-height: 26px;
            width: 308.16px;
           }
          }
      }
  }



 &-section {
  @include breakpoint('lg_down') {
    height: 905px;
 }

 @include breakpoint('md_down') {
  height: 930.46px;
 }
  .mini-title {
      margin-top: 43.63px;
  }

   &-wrapper {
    position: relative;
    .next-section {
      @include breakpoint('lg_down') {
        bottom: 0;
     }
    }
   }
 
 }

 .btn {
    color: var(--color-white);
    border-color: #E2857E;
    margin-top: 47.59px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    min-width: 254.91px;
    height: 44px;
 }
}

.technology-wrapper, .relationships-wrapper, .insights-wrapper {
  position: relative;
}

